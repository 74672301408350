import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { IconButton } from '@material-ui/core';

import logo from 'assets/img/logo.svg';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import SideNavItem from 'components/common/SideNavItem/SideNavItem';
import { capitalize } from 'helpers/stringHelper';
import { SideNavigationItem } from 'components/common/SideNavRenderer/sideNavItems';

const drawerWidth = 74;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        zIndex: 1500,
        position: 'relative',
    },
    logoWrapper: {
        borderRadius: 0,
        height: '100%',
    },
    logoContainer: {
        textAlign: 'center',
        minHeight: 63,
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: CustomColors.heading,
        height: 1, //needs to be at least one so cypress marks it as visible
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: CustomColors.heading,
        overflow: 'hidden',
    },
    toolbar: theme.mixins.toolbar,
}));
export interface SideNavProps {
    menuItems: SideNavigationItem[] | null;
    id: string;
}

export function SideNav(props: SideNavProps): React.ReactElement {
    const classes = useStyles();
    const renderList = items => {
        return Object.keys(items).map(key => {
            return <SideNavItem key={key} {...items[key]} />;
        });
    };

    return (
        <nav className={classes.root}>
            <CssBaseline />
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
                data-cy={`SideNav-${capitalize(props.id)}`}
            >
                <div className={classnames(classes.toolbar, classes.logoContainer)}>
                    <Link to="/">
                        <IconButton
                            className={classnames('admin-header--button', classes.logoWrapper)}
                            color="inherit"
                            aria-label="menu"
                            data-cy="SideNav-Logo"
                        >
                            <img src={logo} alt="logo" />
                        </IconButton>
                    </Link>
                </div>
                <Divider />
                {props.menuItems && props.menuItems.length > 0 && <List>{renderList(props.menuItems)}</List>}
            </Drawer>
        </nav>
    );
}
