import { Container, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            fontSize: '2rem',
        },
    }),
);
export interface SEOOverviewProps {
    optional?: string;
}

const SEOOverview: React.FC<SEOOverviewProps> = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="xl">
            <Box component="div" m={1}>
                <Typography variant="subtitle1" component="h1" className={classes.title}>
                    SEO Home
                </Typography>
            </Box>
        </Container>
    );
};
export default SEOOverview;
