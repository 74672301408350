import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
    deleteVendorCustomPage as apiDeleteVendorCustomPage,
    CreateVendorCustomPageDto,
    getCustomPage,
    PageDto,
    patchCustomPage,
    postCustomPage,
    UpdateVendorCustomPageDto,
} from 'apis/page';
import {
    CATEGORY_TYPE,
    getSeoPages,
    getStorefrontPage,
    putStorefrontPage,
    SeoPagesDto,
    StorefrontPageDto,
    StorefrontPageVendorUpdateDto,
    synchronizeStorefrontPages,
} from 'apis/seoPages';
import { showStatusMessage } from 'application/campaignForm/campaignCreate/visualFeedbackStore';
import { ReduxAction } from 'common/genericTypes';
import { getInitialRequestState, RequestState, RequestType } from 'common/RequestState';
import _ from 'lodash';

type SeoPagesState = {
    list: RequestType<SeoPagesDto[], SeoPagesDto[]>;
    sfpSync: RequestType<SeoPagesDto[], SeoPagesDto[]>;
    sfPage: RequestType<StorefrontPageDto>;
    sfForm: RequestType<void>;
    page: RequestType<PageDto>;
    pageForm: RequestType<void>;
};

type SetPayloadActionType<S extends keyof SeoPagesState> = {
    store: S;
    value: SeoPagesState[S]['payload'];
};

const handleError = (dispatch: Dispatch, error: any) => {
    if (error && typeof error.message === 'string') {
        dispatch(showStatusMessage(error.message, 'error'));
    }
    if (error && Array.isArray(error.message) && error.message.every(e => typeof e === 'string')) {
        // nestjs class-validator errors
        dispatch(showStatusMessage(['Validation Error', ...error.message].join('\n'), 'error'));
    }
};

const initialState: SeoPagesState = {
    list: getInitialRequestState([]),
    sfpSync: getInitialRequestState([]),
    sfPage: getInitialRequestState(),
    sfForm: getInitialRequestState(),
    page: getInitialRequestState(),
    pageForm: getInitialRequestState(),
} as const;

export const seoPagesSlice = createSlice({
    name: 'seoPages',
    initialState,
    reducers: {
        startLoading(state, action: ReduxAction<keyof SeoPagesState>) {
            state[action.payload].requestState = RequestState.InProgress;
        },
        loadCompleted(state, action: ReduxAction<keyof SeoPagesState>) {
            state[action.payload].requestState = RequestState.Finished;
        },
        loadFailed(state, action: ReduxAction<keyof SeoPagesState>) {
            state[action.payload].requestState = RequestState.Failed;
        },
        setPayload<S extends keyof SeoPagesState>(state, action: ReduxAction<SetPayloadActionType<S>>) {
            state[action.payload.store].payload = action.payload.value;
        },
    },
});

export function fetchSeoPages() {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(seoPagesSlice.actions.startLoading('list'));
        try {
            const seoPagesResponse = await getSeoPages();
            dispatch(
                seoPagesSlice.actions.setPayload({
                    store: 'list',
                    value: seoPagesResponse,
                }),
            );
            dispatch(seoPagesSlice.actions.loadCompleted('list'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(seoPagesSlice.actions.loadFailed('list'));
        }
    };
}

export function syncStorefrontPages() {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(seoPagesSlice.actions.startLoading('sfpSync'));
        try {
            const { created, updated } = await synchronizeStorefrontPages();
            const message = [
                created.length && `${created.length} pages was created.`,
                updated.length && `${updated.length} page URLs was updated.`,
            ].filter(Boolean);

            dispatch(
                showStatusMessage(
                    message.length ? message.join(' ') : 'No changes were applied. Everything is up-to-date',
                    message.length ? 'success' : 'info',
                ),
            );

            if (created.length) {
                const grouped = _.groupBy(created, page => page.pageId);
                dispatch(
                    seoPagesSlice.actions.setPayload({
                        store: 'sfpSync',
                        value: Object.entries(grouped).map(([pageId, pages]) => ({
                            pageId,
                            category: CATEGORY_TYPE.SFP,
                            vendors: pages.map(p => ({ name: p.vendorName, url: p.pageUrl })),
                        })),
                    }),
                );
            }

            dispatch(seoPagesSlice.actions.loadCompleted('sfpSync'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(seoPagesSlice.actions.loadFailed('sfpSync'));
        }
    };
}

export function fetchStorefrontPage(pageId: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(seoPagesSlice.actions.startLoading('sfPage'));
        try {
            const seoPagesResponse = await getStorefrontPage(pageId);
            dispatch(
                seoPagesSlice.actions.setPayload({
                    store: 'sfPage',
                    value: seoPagesResponse,
                }),
            );
            dispatch(seoPagesSlice.actions.loadCompleted('sfPage'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(seoPagesSlice.actions.loadFailed('sfPage'));
        }
    };
}

export function updateStorefrontPage(pageId: string, vendorName: string, metadata: StorefrontPageVendorUpdateDto) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(seoPagesSlice.actions.startLoading('sfForm'));
        try {
            await putStorefrontPage(pageId, vendorName, metadata);
            const seoPagesResponse = await getStorefrontPage(pageId);
            dispatch(
                seoPagesSlice.actions.setPayload({
                    store: 'sfPage',
                    value: seoPagesResponse,
                }),
            );
            dispatch(showStatusMessage('Metadata updated successfully', 'success'));

            dispatch(seoPagesSlice.actions.loadCompleted('sfForm'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(seoPagesSlice.actions.loadFailed('sfForm'));
        }
    };
}

export function fetchCustomPage(pageId: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(seoPagesSlice.actions.startLoading('page'));
        dispatch(
            seoPagesSlice.actions.setPayload({
                store: 'page',
                value: undefined,
            }),
        );
        try {
            const seoPagesResponse = await getCustomPage(pageId);
            dispatch(
                seoPagesSlice.actions.setPayload({
                    store: 'page',
                    value: seoPagesResponse,
                }),
            );
            dispatch(seoPagesSlice.actions.loadCompleted('page'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(seoPagesSlice.actions.loadFailed('page'));
        }
    };
}

export function createCustomPage(data: CreateVendorCustomPageDto) {
    return async (dispatch: Dispatch): Promise<boolean> => {
        dispatch(seoPagesSlice.actions.startLoading('pageForm'));
        try {
            await postCustomPage(data);
            const seoPagesResponse = await getCustomPage(data.pageData.pageId);
            dispatch(
                seoPagesSlice.actions.setPayload({
                    store: 'page',
                    value: seoPagesResponse,
                }),
            );
            dispatch(showStatusMessage('Page created successfully', 'success'));

            dispatch(seoPagesSlice.actions.loadCompleted('pageForm'));
            return true;
        } catch (error) {
            handleError(dispatch, error);
            dispatch(seoPagesSlice.actions.loadFailed('pageForm'));
            return false;
        }
    };
}

export function updateVendorCustomPage(pageId: string, vendorName: string, data: UpdateVendorCustomPageDto) {
    return async (dispatch: Dispatch): Promise<boolean> => {
        dispatch(seoPagesSlice.actions.startLoading('pageForm'));
        try {
            await patchCustomPage(pageId, vendorName, data);
            const seoPagesResponse = await getCustomPage(pageId);
            dispatch(
                seoPagesSlice.actions.setPayload({
                    store: 'page',
                    value: seoPagesResponse,
                }),
            );
            dispatch(showStatusMessage('Page updated successfully', 'success'));

            dispatch(seoPagesSlice.actions.loadCompleted('pageForm'));
            return true;
        } catch (error) {
            handleError(dispatch, error);
            dispatch(seoPagesSlice.actions.loadFailed('pageForm'));
            return false;
        }
    };
}

export function deleteVendorCustomPage(pageId: string, vendorName: string) {
    return async (dispatch: Dispatch): Promise<{ success: boolean; otherVendorsAvailable: boolean }> => {
        let success = false;
        let otherVendorsAvailable = false;

        dispatch(seoPagesSlice.actions.startLoading('pageForm'));
        try {
            await apiDeleteVendorCustomPage(pageId, vendorName);
            success = true;
            const seoPagesResponse = await getCustomPage(pageId);
            otherVendorsAvailable = true;
            dispatch(
                seoPagesSlice.actions.setPayload({
                    store: 'page',
                    value: seoPagesResponse,
                }),
            );
            dispatch(seoPagesSlice.actions.loadCompleted('pageForm'));
        } catch (error) {
            if (
                !success || // If delete was not successful
                (!otherVendorsAvailable && error.responseData?.statusCode !== 404) // or page retrieval failed not with 404 code
            ) {
                dispatch(seoPagesSlice.actions.loadFailed('pageForm'));
                handleError(dispatch, error);
            } else {
                dispatch(seoPagesSlice.actions.loadCompleted('pageForm'));
            }
        }

        if (success) {
            dispatch(showStatusMessage('Page deleted successfully', 'success'));
        }

        return {
            success,
            otherVendorsAvailable,
        };
    };
}
