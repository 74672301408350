import { RedirectTransport, UserManager } from '@albelli/oidc';
import { parseJwt } from 'helpers/parseJwt';
import { getAppConfig } from './configService';
import { handleError } from './errorService';

function createOidcManager() {
    const config = getAppConfig();
    if (!config) {
        throw new Error('Cannot create Oidc UserManager. Config is not loaded yet.');
    }

    return new UserManager({
        authority: config.AlbelliAuth.Authority,
        client_type: 'AuthorizationCodeWithProofKey',
        client_id: config.AlbelliAuth.ClientID,
        client_secret: config.AlbelliAuth.ClientSecret,
        scope: (config.AlbelliAuth.Scopes || []).join(' '),
        transport: new RedirectTransport({ redirect_uri: window.location.origin }),
        extra: {
            acr_values: 'idp:albellidomain',
        },
    });
}

function getOidcManager() {
    if (!window.oidcManager) {
        window.oidcManager = oidc.createOidcManager();
    }

    return window.oidcManager;
}

async function getAccessToken() {
    try {
        return await getOidcManager().getAccessToken();
    } catch (e) {
        handleError({ ...e, ...{ message: 'AccessToken Error: ' + e.message } });
    }
    return null;
}

async function getCurrentUserJwt() {
    return parseJwt(await getAccessToken());
}

async function getCurrentUserEmail() {
    const jwt = await oidc.getCurrentUserJwt();
    return jwt.email;
}

async function getCurrentUserAccountId() {
    const jwt = await oidc.getCurrentUserJwt();
    return jwt.sub;
}

export const oidc = {
    createOidcManager,
    getOidcManager,
    getAccessToken,
    getCurrentUserJwt,
    getCurrentUserEmail,
    getCurrentUserAccountId,
};
