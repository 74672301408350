import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import logo from 'assets/img/logo.svg';
import Nav from 'components/common/Nav/Nav';
import React, { BaseSyntheticEvent } from 'react';
import { UserInfo } from 'react-adal';
import { Link } from 'react-router-dom';

type HeaderProps = {
    userInfo: UserInfo;
    logout: Function;
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            marginLeft: -3,
        },
        navWrapper: {
            minHeight: 64,
            display: 'flex',
            flexDirection: 'row',
            padding: `0 0 0 ${theme.spacing(6)}px`,
            width: '100%',
            justifyContent: 'space-between',
        },
        appBar: {
            position: 'relative',
            zIndex: 1501,
        },
        logOutLink: {
            marginTop: `${theme.spacing(5)}px`,
        },
    }),
);

export function Header(props: HeaderProps): React.ReactElement {
    const [auth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const userInfo = props.userInfo;
    const logout = props.logout;

    const handleMenu = (event: BaseSyntheticEvent): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleLogout = (): void => {
        setAnchorEl(null);
        logout();
    };

    const classes = useStyles();

    return (
        <header>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Link to="/">
                        <IconButton edge="start" color="inherit" aria-label="menu">
                            <img src={logo} className={classes.logo} alt="logo" />
                        </IconButton>
                    </Link>
                    <Box className={classes.navWrapper}>
                        <Nav></Nav>
                        {auth && (
                            <Box display="flex" flexWrap="wrap" alignContent="center">
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="admin-appbar-menu"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <Typography variant="body1" className="">
                                        Welcome, {userInfo.profile.name}
                                    </Typography>
                                    <Box ml={1}>
                                        <AccountCircle />
                                    </Box>
                                </IconButton>

                                <Menu
                                    id="admin-appbar-menu"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem className={classes.logOutLink} onClick={handleLogout}>
                                        Log Out
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
        </header>
    );
}
