import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
    BnclCampaignDto,
    BnclCampaignsDto,
    BnclOffer,
    BnclOfferStatus,
    BnclVendorCampaign,
    CampaignCategory,
    countBnclDiscountDetailsPrice,
    createBnclCampaign,
    deleteBnclCampaign,
    getBnclCampaign,
    getBnclCampaigns,
    PageDetailsDto,
    updateBnclCampaign,
    uploadBnclImages,
    VendorCampaignStatus,
} from 'apis/bnclCampaign';
import { addMinutes, addSeconds, isEqual } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { postAttribution } from 'apis/attributionApi/attributions';
import { getChannels } from 'apis/attributionApi/channels';
import { getMediums } from 'apis/attributionApi/mediums';
import { postTrackingCode } from 'apis/attributionApi/trackingCodes';
import { createVoucherExtraOption, updateVoucherExtraOption } from 'apis/catalogueApi';
import { getDiscount, postDiscount, putDiscount } from 'apis/promotionsApi';
import { showStatusMessage } from 'application/campaignForm/campaignCreate/visualFeedbackStore';
import { ReduxAction } from 'common/genericTypes';
import { getInitialRequestState, RequestState, RequestType } from 'common/RequestState';
import { BnclOfferResult } from 'components/vouchers/VendorBncl/BnclOfferAccordion';
import { buildDiscountDefinition } from 'helpers/bnclHelper';
import { preventBrowserClose } from 'helpers/browserEvents';
import { parseDateToAtom } from 'helpers/dateHelper';
import { DiscountLayer, DiscountStatus, DiscountUsageType } from 'models/discounts/discount';
import { Vendor } from 'models/vendor';
import { getAppConfig } from 'services/configService';
import { VENDORS_PREDESIGNED_PRODUCT_IDS } from '../../config/vendorsPredesignedProductIds';

type SaveBnclActionType = {
    status: VendorCampaignStatus;
    vendor: Vendor;
    campaign: {
        name: string;
        endDate: Date;
        category: CampaignCategory;
    };
    offers: BnclOfferResult[];
    pageDetails: PageDetailsDto;
};

type DuplicateCampaignSettingsType = {
    vendorsToUse: Vendor[];
    campaign: {
        name: string;
        endDate: Date;
        category: CampaignCategory;
    };
    options: {
        includeDeactivated: boolean;
        useExpirationDate: boolean;
        expirationDate: Date;
    };
};

export type BnclListFiltersType = {
    name: string;
    category: string;
    showPublished: boolean;
    showDraft: boolean;
    showUnpublished: boolean;
    showExpired: boolean;
};

type BnclState = {
    list: RequestType<BnclCampaignsDto[], BnclCampaignsDto[]>;
    listFilters: BnclListFiltersType;
    bnclCampaign: RequestType<BnclCampaignDto>;
    bnclSave: RequestType<void>;
    bnclDuplicate: RequestType<void>;
};

type BnclRequestsState = Omit<BnclState, 'listFilters'>;

type SetPayloadActionType<S extends keyof BnclRequestsState> = {
    store: S;
    value: BnclRequestsState[S]['payload'];
};

const handleError = (dispatch: Dispatch, error: any) => {
    if (error && typeof error.message === 'string') {
        dispatch(showStatusMessage(error.message, 'error'));
    }
    if (error && Array.isArray(error.message) && error.message.every(e => typeof e === 'string')) {
        // nestjs class-validator errors
        dispatch(showStatusMessage(['Validation Error', ...error.message].join('\n'), 'error'));
    }
};

const initialState: BnclState = {
    list: getInitialRequestState([]),
    listFilters: {
        name: '',
        category: '',
        showPublished: true,
        showDraft: true,
        showUnpublished: false,
        showExpired: false,
    },
    bnclCampaign: getInitialRequestState(),
    bnclSave: getInitialRequestState(),
    bnclDuplicate: getInitialRequestState(),
} as const;

export const bnclSlice = createSlice({
    name: 'bncl',
    initialState,
    reducers: {
        startLoading(state, action: ReduxAction<keyof BnclRequestsState>) {
            state[action.payload].requestState = RequestState.InProgress;
        },
        loadCompleted(state, action: ReduxAction<keyof BnclRequestsState>) {
            state[action.payload].requestState = RequestState.Finished;
        },
        loadFailed(state, action: ReduxAction<keyof BnclRequestsState>) {
            state[action.payload].requestState = RequestState.Failed;
        },
        setPayload<S extends keyof BnclRequestsState>(state, action: ReduxAction<SetPayloadActionType<S>>) {
            state[action.payload.store].payload = action.payload.value;
        },
        setFilters(state, action: ReduxAction<BnclListFiltersType>) {
            state.listFilters = action.payload;
        },
    },
});

export function fetchBnclCampaigns() {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(bnclSlice.actions.startLoading('list'));
        try {
            const bnclResponse = await getBnclCampaigns();
            dispatch(
                bnclSlice.actions.setPayload({
                    store: 'list',
                    value: bnclResponse,
                }),
            );
            dispatch(bnclSlice.actions.loadCompleted('list'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(bnclSlice.actions.loadFailed('list'));
        }
    };
}

export function setBnclFilters(filters: BnclListFiltersType) {
    return (dispatch: Dispatch): void => {
        dispatch(bnclSlice.actions.setFilters(filters));
    };
}

export function fetchBnclCampaign(id: string) {
    return async (dispatch: Dispatch, getState: Function): Promise<void> => {
        dispatch(bnclSlice.actions.startLoading('bnclCampaign'));

        const state: BnclState = getState().vouchers.bncl;
        try {
            if (state.bnclCampaign.payload?.campaignId !== id) {
                dispatch(
                    bnclSlice.actions.setPayload({
                        store: 'bnclCampaign',
                        value: undefined,
                    }),
                );
            }
            const campaign = await getBnclCampaign(id);
            dispatch(
                bnclSlice.actions.setPayload({
                    store: 'bnclCampaign',
                    value: campaign,
                }),
            );
            dispatch(bnclSlice.actions.loadCompleted('bnclCampaign'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(bnclSlice.actions.loadFailed('bnclCampaign'));
        }
    };
}

async function createBnclAttribution(
    { campaign, vendor }: SaveBnclActionType,
    offersWithDiscounts: BnclOffer[],
    currentCampaignAttributionList: BnclVendorCampaign['attributions'] | never[],
) {
    const [channels, mediums] = await Promise.all([getChannels(), getMediums()]);

    const channelCode = campaign.category === CampaignCategory.CRM ? 'CRM' : 'OTH';
    const channel = channels.find(c => c.shortCode === channelCode)!;

    const sourceCode = campaign.category === CampaignCategory.CRM ? 'CAM' : 'OTH';
    const source = channel.sources.find(s => s.shortCode === sourceCode)!;

    const mediumCode = campaign.category === CampaignCategory.CRM ? 'EML' : 'WEB';
    const medium = mediums.find(m => m.shortCode === mediumCode)!;

    const offersWithAttribution = await Promise.all(
        offersWithDiscounts.map(async offer => {
            let attributionId;

            if (offer.attributionId !== undefined) {
                attributionId = offer.attributionId;
            } else {
                const attributionForTheOffer = await postAttribution({
                    name: `BNCL - ${campaign.name}`,
                    description: `BNCL\r\nCheckout price: ${vendor.currency.symbol} ${offer.voucherPrice.toFixed(2)}`,
                    channelId: channel.id,
                    sourceId: source.id,
                    mediumId: medium.id,
                    validFrom: new Date(),
                    validUntil: new Date(offer.expirationDate),
                    trackingCodes: [],
                    discountId: offer.discountId,
                    voucherDiscountOptionId: offer.voucherId,
                });

                postTrackingCode({
                    attributionId: attributionForTheOffer.id,
                    suffix: 'BNCL',
                });

                attributionId = attributionForTheOffer.id;
            }

            return {
                ...offer,
                attributionId: attributionId,
            };
        }),
    );

    let resutltingCampaignAttributions;
    const campaignExistingAttribution = currentCampaignAttributionList.find(a => a.category === campaign.category);

    if (campaignExistingAttribution !== undefined) {
        resutltingCampaignAttributions = currentCampaignAttributionList;
    } else {
        const attributionForCampaign = await postAttribution({
            name: `BNCL tracking - ${campaign.name}`,
            description: `bncl campaign for ${vendor.name}`,
            channelId: channel.id,
            sourceId: source.id,
            mediumId: medium.id,
            validFrom: new Date(),
            validUntil: campaign.endDate,
            trackingCodes: [],
        });

        const trackingCode = await postTrackingCode({
            attributionId: attributionForCampaign.id,
            suffix: 'BNCL',
        });

        resutltingCampaignAttributions = [
            {
                id: attributionForCampaign.id,
                category: campaign.category,
                code: trackingCode.code,
            },
        ];
    }

    return {
        campaignAttributions: resutltingCampaignAttributions,
        offersWithAttribution: offersWithAttribution,
    };
}

async function markOffersContentAsExpired(vendorName: string, offers: BnclOffer[]) {
    const expiredTime = addMinutes(new Date(), 1);
    await Promise.all(
        offers.map(async offer => {
            const discount = await getDiscount(offer.discountId);

            await updateVoucherExtraOption(offer.voucherId, vendorName, {
                description: `BNCL - ${offer.productBlockTitle}`.slice(0, 50),
                basePrice: offer.voucherPrice,
                validFrom: expiredTime,
                validTo: addSeconds(expiredTime, 1), // Catalogue validation
                preDesignedProductId: VENDORS_PREDESIGNED_PRODUCT_IDS[vendorName],
            });
            if (discount) {
                await putDiscount({
                    ...discount,
                    validTo: new Date(),
                });
            }
        }),
    );
}

async function saveBnclCampaignFlow(
    input: SaveBnclActionType,
    prevCampaign?: BnclCampaignDto,
): Promise<{ campaignId: string; successMessage: string }> {
    const { status, vendor, campaign, offers, pageDetails } = input;
    let currentCampaignId = prevCampaign?.campaignId;
    let successMessage = 'Campaign updated successfully';

    const prevVendorCampaign = prevCampaign && prevCampaign.vendorCampaignList.find(v => v.vendorName === vendor.name);

    const isCampaignEndDateChanged = prevCampaign && !isEqual(new Date(prevCampaign.endDate), campaign.endDate);

    if (status === VendorCampaignStatus.PUBLISHED && !offers.find(o => o.status === BnclOfferStatus.ACTIVE)) {
        throw new Error('At least one Offer Block must be Active');
    }
    if (
        status === VendorCampaignStatus.DRAFT &&
        prevVendorCampaign &&
        prevVendorCampaign.status !== VendorCampaignStatus.DRAFT
    ) {
        throw new Error('You can not get back to Draft state');
    }

    const removedOffers = (prevVendorCampaign?.offers || []).filter(
        existingOffer => !offers.find(o => o.voucherId === existingOffer.voucherId),
    );

    if (removedOffers.length && prevVendorCampaign && prevVendorCampaign.status !== VendorCampaignStatus.DRAFT) {
        throw new Error('Offers can be removed only in Draft state');
    }

    const imagesToCreate: { img: File; name: string }[] = [];
    const offersWithImages = offers.map(offer => {
        const { image } = offer;
        const fileName = image.file ? image.file.name.replace('.', `-${uuidv4()}.`).replaceAll(' ', '_') : image.path;
        if (image.file) {
            imagesToCreate.push({ img: image.file, name: fileName });
        }

        return {
            ...offer,
            imagePath: fileName,
            image: undefined,
        };
    });

    if (imagesToCreate.length) {
        await uploadBnclImages(imagesToCreate);
    }

    const offersWithDiscounts = await Promise.all(
        offersWithImages.map(async offer => {
            const { discount, voucherId } = offer;
            const prevOffer = prevVendorCampaign?.offers.find(o => o.voucherId === voucherId);

            const expectedDiscountStatus =
                status === VendorCampaignStatus.DRAFT ? DiscountStatus.draft : DiscountStatus.published;
            let discountId: string | undefined = discount.initial?.id;
            let lastPublishDate: Date | undefined = prevOffer?.lastPublishDate
                ? new Date(prevOffer.lastPublishDate)
                : undefined;

            if (discount.action === 'create') {
                const createdDiscount = await postDiscount({
                    definition: discount.code,
                    validTo: offer.expirationDate,
                    validFrom: new Date(),
                    description: `BNCL - ${offer.productBlockTitle}`,
                    usageType: DiscountUsageType.SingleUse,
                    status: expectedDiscountStatus,
                    layer: DiscountLayer.Coupon,
                    tags: ['bncl'],
                });
                discountId = createdDiscount.id;
                lastPublishDate = new Date();
            }
            if (
                discount.initial &&
                (discount.action === 'update' || discount.initial.status !== expectedDiscountStatus)
            ) {
                await putDiscount({
                    ...discount.initial,
                    definition: discount.code,
                    validTo: offer.expirationDate,
                    status: expectedDiscountStatus,
                    layer: DiscountLayer.Coupon,
                });
                lastPublishDate = new Date();
            }

            return {
                ...offer,
                discountId: discountId as string,
                discount: undefined,
                lastPublishDate: lastPublishDate as Date,
            };
        }),
    );

    const offersWithVoucherId = await Promise.all(
        offersWithDiscounts.map(async offer => {
            let { voucherId, lastPublishDate } = offer;

            if (!voucherId) {
                const { extraOptionId } = await createVoucherExtraOption(vendor.name, {
                    description: `BNCL - ${offer.productBlockTitle}`.slice(0, 50), // Catalogue length validation
                    basePrice: offer.voucherPrice,
                    // Catalogue expects dates to be >= now. Due to networking latency, use current date + 1 minute
                    validFrom: addMinutes(new Date(), 1),
                    validTo: campaign.endDate, // available for purchase?
                    preDesignedProductId: VENDORS_PREDESIGNED_PRODUCT_IDS[vendor.name],
                });
                lastPublishDate = new Date();

                voucherId = extraOptionId;
            } else {
                const prevOffer = prevVendorCampaign?.offers.find(o => o.voucherId === offer.voucherId);
                if (
                    prevOffer?.voucherPrice !== offer.voucherPrice ||
                    prevOffer?.productBlockTitle !== offer.productBlockTitle ||
                    isCampaignEndDateChanged
                ) {
                    await updateVoucherExtraOption(voucherId, vendor.name, {
                        description: `BNCL - ${offer.productBlockTitle}`.slice(0, 50),
                        basePrice: offer.voucherPrice,
                        validFrom: addMinutes(new Date(), 1),
                        validTo: campaign.endDate,
                        preDesignedProductId: VENDORS_PREDESIGNED_PRODUCT_IDS[vendor.name],
                    });
                    lastPublishDate = new Date();
                }
            }

            return {
                ...offer,
                voucherId,
                lastPublishDate,
            };
        }),
    );

    let attributions: BnclVendorCampaign['attributions'] | never[] = [];
    let offersWithAttribution;

    // no need to create Attribution during Draft
    if (status !== VendorCampaignStatus.DRAFT) {
        const attributionsResult = await createBnclAttribution(input, offersWithVoucherId as unknown as BnclOffer[], [
            ...(prevVendorCampaign ? prevVendorCampaign.attributions : []),
        ]);
        attributions = attributionsResult.campaignAttributions;
        offersWithAttribution = attributionsResult.offersWithAttribution;
    }

    if (removedOffers.length) {
        await markOffersContentAsExpired(vendor.name, removedOffers);
    }

    const toSend = {
        name: campaign.name,
        startDate: prevCampaign?.startDate || new Date(),
        endDate: campaign.endDate,
        category: campaign.category,
        vendorCampaign: {
            vendorName: vendor.name,
            offers: offersWithAttribution !== undefined ? offersWithAttribution : offersWithVoucherId,
            pageDetails: pageDetails,
            attributions: attributions.map(attr => ({
                ...attr,
                id: String(attr.id) as any as number,
            })),
            status,
        },
    };

    if (prevCampaign && prevVendorCampaign) {
        await updateBnclCampaign(prevCampaign.campaignId, toSend);

        if (prevVendorCampaign.status !== VendorCampaignStatus.PUBLISHED && status === VendorCampaignStatus.PUBLISHED) {
            successMessage = 'Campaign published successfully';
        } else if (
            prevVendorCampaign.status === VendorCampaignStatus.PUBLISHED &&
            status === VendorCampaignStatus.UNPUBLISHED
        ) {
            successMessage = 'Campaign unpublished successfully';
        }
    } else {
        const { campaignId } = await createBnclCampaign({
            campaignId: currentCampaignId,
            ...toSend,
        });
        currentCampaignId = campaignId;
        successMessage =
            status === VendorCampaignStatus.PUBLISHED
                ? 'Campaign published successfully'
                : 'Campaign created successfully';
    }

    return {
        campaignId: currentCampaignId as string,
        successMessage,
    };
}

export function saveBnclCampaign(input: SaveBnclActionType, prevCampaign?: BnclCampaignDto) {
    return async (dispatch: Dispatch): Promise<{ campaignId: string; success: boolean }> => {
        let currentCampaignId = prevCampaign?.campaignId;
        let success = true;
        const unlockClose = preventBrowserClose();

        dispatch(bnclSlice.actions.startLoading('bnclSave'));
        try {
            const { successMessage, campaignId } = await saveBnclCampaignFlow(input, prevCampaign);
            currentCampaignId = campaignId;

            dispatch(showStatusMessage(successMessage, 'success'));
            dispatch(bnclSlice.actions.loadCompleted('bnclSave'));
        } catch (error) {
            success = false;
            handleError(dispatch, error);
            dispatch(bnclSlice.actions.loadFailed('bnclSave'));
        } finally {
            unlockClose();
        }

        return {
            campaignId: currentCampaignId as string,
            success,
        };
    };
}

export function removeBnclCampaign(vendorName: string, campaign: BnclCampaignDto) {
    return async (dispatch: Dispatch): Promise<{ success: boolean }> => {
        const unlockClose = preventBrowserClose();
        let success = true;

        const vendorCampaign = campaign.vendorCampaignList.find(v => v.vendorName === vendorName);

        dispatch(bnclSlice.actions.startLoading('bnclSave'));
        try {
            if (!vendorCampaign) {
                throw new Error('Vendor Campaign does not exist');
            }
            if (vendorCampaign.status !== VendorCampaignStatus.DRAFT) {
                throw new Error('You can not delete a published campaign');
            }

            await markOffersContentAsExpired(vendorName, vendorCampaign.offers);

            await deleteBnclCampaign(campaign.campaignId, vendorName);

            dispatch(bnclSlice.actions.loadCompleted('bnclSave'));
        } catch (error) {
            success = false;
            handleError(dispatch, error);
            dispatch(bnclSlice.actions.loadFailed('bnclSave'));
        } finally {
            unlockClose();
        }

        return {
            success,
        };
    };
}

const imgUrlToFile = async (imageUrl: string) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], imageUrl.split('/').pop() as string, { type: blob.type });
    return file;
};

export function duplicateBnclCampaign(
    srcCampaign: BnclCampaignDto,
    { vendorsToUse, campaign, options }: DuplicateCampaignSettingsType,
) {
    return async (dispatch: Dispatch): Promise<{ campaignId: string } | undefined> => {
        const unlockClose = preventBrowserClose();

        const offersExpirationDate = options.useExpirationDate ? options.expirationDate : campaign.endDate;
        let createdCampaign: BnclCampaignDto | undefined;
        const failedVendors: string[] = [];

        dispatch(bnclSlice.actions.startLoading('bnclDuplicate'));
        for (const vendorCampaign of srcCampaign.vendorCampaignList) {
            const vendor = vendorsToUse.find(v => v.name === vendorCampaign.vendorName);
            if (!vendor) continue;

            try {
                const filteredOffers = vendorCampaign.offers.filter(
                    offer => options.includeDeactivated || offer.status === BnclOfferStatus.ACTIVE,
                );

                const offers = await Promise.all(
                    (filteredOffers.length ? filteredOffers : vendorCampaign.offers).map(async offer => {
                        const [file, { productPrice }] = await Promise.all([
                            imgUrlToFile(new URL(offer.imagePath, getAppConfig().StorefrontAssetsS3Url).href),
                            countBnclDiscountDetailsPrice(vendorCampaign.vendorName, offer.discountDetails),
                        ]);

                        const [fileName, fileExt] = file.name.split('.');
                        const newFileName = fileName.split('-').filter(Boolean).shift() as string;

                        return {
                            discount: {
                                initial: null,
                                action: 'create' as const,
                                code: buildDiscountDefinition(offer.discountDetails, vendor.name),
                            },
                            discountDetails: offer.discountDetails,
                            image: {
                                file: new File([file], `${newFileName}.${fileExt}`, {
                                    type: file.type,
                                    lastModified: file.lastModified,
                                }),
                            },
                            productBlockTitle: offer.productBlockTitle,
                            checkoutLabel: offer.checkoutLabel,
                            voucherPrice: offer.voucherPrice,
                            productPrice,
                            expirationDate: offersExpirationDate,
                            includedOptions: offer.includedOptions,
                            excludedOptions: offer.excludedOptions,
                            status: offer.status,
                            currency: offer.currency,
                        };
                    }),
                );

                const { campaignId } = await saveBnclCampaignFlow(
                    {
                        status: VendorCampaignStatus.DRAFT,
                        vendor,
                        campaign,
                        offers,
                        pageDetails: vendorCampaign.pageDetails,
                    },
                    createdCampaign,
                );

                if (!createdCampaign) {
                    createdCampaign = {
                        campaignId,
                        name: campaign.name,
                        startDate: parseDateToAtom(new Date()),
                        endDate: parseDateToAtom(campaign.endDate),
                        category: campaign.category,
                        vendorCampaignList: [],
                    };
                }
            } catch (err) {
                console.error('Error duplicating campaign', vendorCampaign.vendorName, err);
                failedVendors.push(vendorCampaign.vendorName);
                continue;
            }
        }
        unlockClose();

        if (!createdCampaign && !failedVendors.length) {
            dispatch(showStatusMessage('Nothing to Duplicate', 'info'));
        } else if (!createdCampaign && failedVendors.length) {
            dispatch(showStatusMessage('Duplication Failed', 'error'));
        } else if (createdCampaign && failedVendors.length) {
            dispatch(
                showStatusMessage(
                    `Not all Vendors were duplicated. ${failedVendors.join(', ')} need to be resolved manually`,
                    'error',
                ),
            );
        } else {
            dispatch(showStatusMessage('Duplicated Successfully', 'success'));
        }

        dispatch(bnclSlice.actions.loadCompleted('bnclDuplicate'));

        return createdCampaign;
    };
}
