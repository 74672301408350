import { TextField, Tooltip } from '@material-ui/core';
import { AccountAuditDto } from 'apis/b2bAccounts';
import { useCopier } from 'components/hooks/useCopier';
import { capitalize } from 'helpers/stringHelper';
import React, { useMemo } from 'react';
import AccountChangesPopper from './AccountChangesPopper';

type TextFieldHistoryProps = {
    label: string | JSX.Element;
    value: unknown;
    audit?: AccountAuditDto[];
    propName?: string[] | string;
    isMultiple?: boolean;
};

export default function TextFieldHistory({
    label,
    value,
    audit = [],
    propName = '',
    isMultiple = false,
}: TextFieldHistoryProps): React.ReactElement {
    const [copiedText, onCopy] = useCopier();

    const historyProps = (Array.isArray(propName) ? propName : [propName]).map(capitalize);

    const history = useMemo(() => {
        return audit.filter(a => !!historyProps.find(p => a.changedFields[p]));
    }, [audit, propName]);

    return (
        <Tooltip title={copiedText ? 'Copied!' : ''}>
            <TextField
                disabled
                label={label}
                value={value || ''}
                InputProps={{
                    onClick: () => {
                        onCopy((value || '') as string);
                    },
                    endAdornment: history.length > 0 && (
                        <AccountChangesPopper audit={history} propsToDisplay={historyProps} isMultiple={isMultiple} />
                    ),
                }}
                inputProps={{
                    style: { pointerEvents: 'none' },
                }}
                InputLabelProps={{ shrink: true }}
                data-cy={'B2bAccountModal-Input-' + historyProps.join('-')}
            />
        </Tooltip>
    );
}
