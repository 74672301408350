import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '2rem',
        },
    }),
);

export function Home() {
    const classes = useStyles();
    return (
        <Container maxWidth="xl">
            <Box component="div" m={1}>
                <Typography variant="subtitle1" component="h1" className={classes.title}>
                    Admin Panel Home Page
                </Typography>
            </Box>
        </Container>
    );
}
