import { Badge, Box, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { BnclCampaignDto, VendorCampaignStatus } from 'apis/bnclCampaign';
import { fetchCatalog } from 'application/discounts/promotionCatalogSlice';
import { fetchBnclCampaign, removeBnclCampaign, saveBnclCampaign } from 'application/vouchers/bnclSlice';
import { Loader } from 'components/common/Loader/Loader';
import { VendorCheckboxPicker, VendorPickedTabs, useVendorPicker } from 'components/common/VendorPicker';
import { isInProgress, isLoading, isNotRequested } from 'helpers/requestStateHelper';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { routes } from '../../../routes';
import VendorBnclCampaign, { VendorCampaignHandlers } from '../VendorBncl/VendorBnclCampaign';
import BnclCampaignForm, { BnclCampaignFormHandlers } from './BnclCampaignForm';

type CampaignEditorProps = {
    campaign?: BnclCampaignDto;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: { margin: '44.5px' },
        header: {
            fontSize: '2rem',
        },
        campaignFormContainer: {
            marginTop: '50px',
            marginBottom: theme.spacing(5),
        },
        tab_draft: {
            '& .MuiBadge-badge': {
                backgroundColor: theme.palette.warning.dark,
            },
        },
        tab_published: {
            '& .MuiBadge-badge': {
                backgroundColor: theme.palette.success.dark,
            },
        },
        tab_unpublished: {
            '& .MuiBadge-badge': {
                backgroundColor: theme.palette.error.dark,
            },
        },
    }),
);

export default function CampaignEditor({ campaign }: CampaignEditorProps): React.ReactElement {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory<{ force?: boolean }>();
    const vendorPickerState = useVendorPicker();
    const { allVendors, currentVendor, setPickedVendors } = vendorPickerState;

    const vendors = useSelector(state => state.apiConfig.vendors);
    const catalog = useSelector(state => state.promotionCatalog);
    const bnclSave = useSelector(state => state.vouchers.bncl.bnclSave);
    const [initialCampaign, setInitialCampaign] = useState<BnclCampaignDto | null>(null);

    const campaignRef = useRef<BnclCampaignFormHandlers>();
    const vendorCampaignRef = useRef<VendorCampaignHandlers>();

    const onSubmit = async (status: VendorCampaignStatus) => {
        const [campaignResult, vendorCampaign] = await Promise.all([
            campaignRef.current?.submit(),
            vendorCampaignRef.current?.submit(),
        ]);

        if (!campaignResult || !vendorCampaign) return;

        const { campaignId, success } = await dispatch(
            saveBnclCampaign(
                {
                    status,
                    vendor: currentVendor!,
                    campaign: campaignResult,
                    ...vendorCampaign,
                },
                campaign,
            ),
        );

        if (history.location.pathname === routes.newBnclCampaign && success) {
            history.push(routes.editBnclCampaign.replace(':campaignId', campaignId), { force: true });
        } else if (success) {
            dispatch(fetchBnclCampaign(campaignId));
        }
    };

    const onDelete = async (vendorName: string) => {
        if (!campaign) return;
        const { success } = await dispatch(removeBnclCampaign(vendorName, campaign));

        if (success && campaign.vendorCampaignList.length === 1) {
            history.push(routes.bnclCampaigns, { force: true });
        } else if (success) {
            dispatch(fetchBnclCampaign(campaign.campaignId));
        }
    };

    useEffect(() => {
        if (!campaign) return;
        setInitialCampaign(campaign);
        setPickedVendors(
            allVendors.filter(vendor => campaign.vendorCampaignList.find(item => item.vendorName === vendor.name)),
        );
    }, [campaign, allVendors]);

    useEffect(() => {
        if (isNotRequested(catalog)) dispatch(fetchCatalog());
    }, []);

    const tabLabels = useMemo(() => {
        if (!campaign) return {};
        return campaign.vendorCampaignList.reduce(
            (acc, { vendorName, status }) => ({
                ...acc,
                [vendorName]: (
                    <Badge className={classes['tab_' + status]} variant="dot" overlap="rectangular">
                        {vendorName}
                    </Badge>
                ),
            }),
            {} as { [vendorName: string]: React.ReactNode },
        );
    }, [campaign]);

    useEffect(() => {
        const unblock = history.block(location => {
            if (location.state && location.state.force) return;
            // not checking campaignRef.isModified() since it is available for all tabs
            const shouldAskOnLeave = vendorCampaignRef.current?.isModified();
            if (shouldAskOnLeave) {
                return 'You’ve got unsaved changes. Are you sure you want to leave this page?';
            }
        });

        return () => {
            unblock();
        };
    }, []);

    if (isLoading(vendors) || isLoading(catalog)) {
        return <Loader />;
    }

    return (
        <Box className={classes.wrapper} data-cy="Bncl-Campaign-Editor">
            {isInProgress(bnclSave) && <Loader />}
            <Typography variant="subtitle1" component="h1" className={classes.header}>
                Bncl Campaign Editor
            </Typography>
            <Box>
                <Box className={classes.campaignFormContainer}>
                    <BnclCampaignForm ref={campaignRef} campaign={initialCampaign}>
                        <VendorCheckboxPicker
                            isDisabled={(vendorName: string) =>
                                !!campaign?.vendorCampaignList.find(v => v.vendorName === vendorName)
                            }
                            cy="Bncl-Vendor-Checkbox-"
                            {...vendorPickerState}
                        />
                    </BnclCampaignForm>
                </Box>

                {currentVendor && (
                    <VendorPickedTabs {...vendorPickerState} customLabels={tabLabels}>
                        <VendorBnclCampaign
                            ref={vendorCampaignRef}
                            vendor={currentVendor}
                            vendorCampaign={initialCampaign?.vendorCampaignList.find(
                                c => c.vendorName === currentVendor.name,
                            )}
                            campaignId={initialCampaign?.campaignId}
                            onSubmit={onSubmit}
                            onDelete={onDelete}
                        />
                    </VendorPickedTabs>
                )}
            </Box>
        </Box>
    );
}
