import { TextField, TextFieldProps } from '@material-ui/core';
import * as React from 'react';

export type FormikTextFieldProps = TextFieldProps & { formik: any };

export const FormikTextField = ({ formik, name, required, ...props }: FormikTextFieldProps): React.ReactElement => {
    if (!name) throw Error('name prop is required');

    return (
        <TextField
            name={name}
            value={formik.values[name]}
            error={!!formik.touched[name] && !!formik.errors[name]}
            helperText={!!formik.touched[name] && formik.errors[name]}
            onChange={formik.handleChange}
            {...props}
            InputLabelProps={{ shrink: true, required }}
        />
    );
};
