import { Box, FormHelperText, Theme, createStyles, makeStyles } from '@material-ui/core';
import { DiscountEditor, DiscountsPanelState } from 'components/discounts/DiscountEditor/DiscountEditor';
import * as React from 'react';
import { CustomColors } from '../CustomTheme/CustomTheme';

export type FormikDiscountEditorProps = Omit<DiscountsPanelState, 'code' | 'onCodeChanged'> & {
    formik: any;
    name: string;
    hidden?: boolean;
    cy?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            margin: '1px',
        },
        box: {
            border: `1px solid ${CustomColors.border}`,
            borderRadius: '3px',
        },
        error: {
            border: `2px solid ${theme.palette.error.main}`,
            marginLeft: '-3px',
            marginTop: '-2px',
        },
        hidden: {
            display: 'none',
        },
    }),
);

export const FormikDiscountEditor = ({
    formik,
    name,
    hidden = false,
    cy,
    ...props
}: FormikDiscountEditorProps): React.ReactElement => {
    if (!name) throw Error('name prop is required');

    const classes = useStyles();

    const hasError = !!formik.touched[name] && !!formik.errors[name];

    const onChange = (val: string) => {
        formik.setFieldValue(name, val);
    };

    return (
        <Box className={classes.container} data-cy={cy}>
            <Box
                className={`${classes.box} ${hasError && classes.error} ${hidden && classes.hidden}`}
                key={String(hidden)}
            >
                <DiscountEditor {...props} onCodeChanged={onChange} code={formik.values[name]} />
            </Box>
            {hasError && <FormHelperText error={true}>{formik.errors[name]}</FormHelperText>}
        </Box>
    );
};
