import { AppBar, Box, Grid, Theme, Toolbar, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BnclCampaignDto, BnclOfferStatus } from 'apis/bnclCampaign';
import { duplicateBnclCampaign } from 'application/vouchers/bnclSlice';
import { CampaignTitle } from 'components/campaigns/CampaignForm/CampaignTitle';
import CustomButton from 'components/common/CustomButton/CustomButton';
import { FormikCheckbox } from 'components/common/Form/FormikCheckbox';
import { FormikDateTimePicker } from 'components/common/Form/FormikDateTimePicker';
import { Loader } from 'components/common/Loader/Loader';
import { VendorCheckboxPicker } from 'components/common/VendorPicker';
import { isPast } from 'date-fns';
import { FormikErrors, useFormik } from 'formik';
import { isValidDate, parseAtomToDate } from 'helpers/dateHelper';
import { isInProgress } from 'helpers/requestStateHelper';
import { Vendor } from 'models/vendor';
import * as React from 'react';
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { useDispatch, useSelector } from 'store';
import BnclCampaignForm, { BnclCampaignFormHandlers } from './BnclCampaignForm';

const LazyModal = React.lazy(() => import('components/common/Modal/Modal').then(({ Modal }) => ({ default: Modal })));

type FormType = {
    includeDeactivated: boolean;
    useExpirationDate: boolean;
    expirationDate: Date;
};

type DuplicateCampaignProps = {
    campaign: BnclCampaignDto;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionsBlock: {
            textAlign: 'right',
            '& button + button': {
                marginLeft: theme.spacing(2),
            },
        },
    }),
);

const validate = (values: FormType) => {
    const errors: FormikErrors<FormType> = {};

    if (values.useExpirationDate) {
        if (!values.expirationDate) {
            errors.expirationDate = 'Expiration date is required';
        } else if (!isValidDate(values.expirationDate)) {
            errors.expirationDate = 'Expiration date should be a valid date';
        } else if (isPast(values.expirationDate)) {
            errors.expirationDate = 'Expiration date should not be in the past';
        }
    }

    return errors;
};

export default function DuplicateCampaign({ campaign }: DuplicateCampaignProps): React.ReactElement {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory<{ force?: boolean }>();
    const [isOpen, setIsOpen] = useState(false);
    const [pickedVendors, setPickedVendors] = useState<Vendor[]>([]);
    const vendors = useSelector(state => state.apiConfig.vendors);
    const duplicateRequest = useSelector(state => state.vouchers.bncl.bnclDuplicate);

    const campaignRef = useRef<BnclCampaignFormHandlers>();

    const formik = useFormik<FormType>({
        initialValues: {
            includeDeactivated: true,
            useExpirationDate: false,
            expirationDate: parseAtomToDate(campaign.endDate),
        },
        onSubmit: async _ => _,
        validate,
    });

    useEffect(() => {
        if (!vendors.data) return;
        setPickedVendors(vendors.data.filter(v => campaign.vendorCampaignList.find(vc => vc.vendorName === v.name)));
    }, [campaign, vendors.data]);

    useEffect(() => {
        if (!isValidDate(formik.values.expirationDate)) {
            formik.setFieldValue('useExpirationDate', false);
        } else if (formik.values.expirationDate.getTime() !== formik.initialValues.expirationDate.getTime()) {
            formik.setFieldValue('useExpirationDate', true);
        }
    }, [formik.values.expirationDate]);

    const freshCampaign = useMemo(() => ({ ...campaign, vendorCampaignList: [] }), [campaign]);
    const inactiveOffersTotal = useMemo(() => {
        return campaign.vendorCampaignList.reduce((sum, vc) => {
            return sum + vc.offers.filter(o => o.status === BnclOfferStatus.INACTIVE).length;
        }, 0);
    }, [campaign]);

    const onVendorCheck = (vendor: Vendor, checked: boolean) => {
        if (checked) {
            setPickedVendors([...pickedVendors, vendor]);
        } else {
            setPickedVendors(pickedVendors.filter(item => item.id !== vendor.id));
        }
    };

    const onSubmit = async () => {
        const campaignForm = await campaignRef.current?.submit();
        const options = await formik.submitForm();
        if (!campaignForm || !options) return;

        const createdCampaign = await dispatch(
            duplicateBnclCampaign(campaign, { vendorsToUse: pickedVendors, campaign: campaignForm, options }),
        );
        if (createdCampaign) {
            history.push(routes.editBnclCampaign.replace(':campaignId', createdCampaign.campaignId), { force: true });
        }
    };

    return (
        <>
            <Suspense fallback={<Loader />}>
                <LazyModal open={isOpen} disableBackdropClick={true} handleClose={() => setIsOpen(false)}>
                    <>
                        {isInProgress(duplicateRequest) && <Loader />}
                        <Box alignContent="center" mb={1}>
                            <Typography variant="h5">Duplicate &quot;{campaign.name}&quot; BNCL Campaign</Typography>
                        </Box>
                        <BnclCampaignForm ref={campaignRef} campaign={freshCampaign}>
                            <>
                                <VendorCheckboxPicker
                                    allVendors={vendors.data}
                                    pickedVendors={pickedVendors}
                                    onVendorCheck={onVendorCheck}
                                    isDisabled={vendorName =>
                                        !campaign.vendorCampaignList.find(vc => vc.vendorName === vendorName)
                                    }
                                    cy="Bncl-DuplicateVendor-Checkbox-"
                                    title="Which Vendor Campaigns should be Duplicated"
                                />
                                <Grid container spacing={2}>
                                    {!!inactiveOffersTotal && (
                                        <Grid item xs={12}>
                                            <FormikCheckbox
                                                label={`Also duplicate ${inactiveOffersTotal} deactivated Offer(s)`}
                                                name="includeDeactivated"
                                                formik={formik}
                                                cy="Bncl-DuplicateForm-IncludeDeactivated"
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Grid container direction="row" alignItems="center">
                                            <FormikCheckbox
                                                label="Use this Discount expiry date for every Offer:"
                                                name="useExpirationDate"
                                                formik={formik}
                                                cy="Bncl-DuplicateForm-UseExpirationDate"
                                            />
                                            <FormikDateTimePicker
                                                label="Discount expiry date"
                                                name="expirationDate"
                                                required={formik.values.useExpirationDate}
                                                formik={formik}
                                                InputLabelProps={{ shrink: true }}
                                                data-cy="Bncl-DuplicateForm-ExpiryDate"
                                                showTzHints
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Box mt={1}>
                                    <Alert severity="warning">
                                        All Vendors will be in a Draft state after Duplication. You will have to Review
                                        and Publish them one by one.
                                    </Alert>
                                </Box>
                            </>
                        </BnclCampaignForm>
                        <Box pr={3} className={classes.actionsBlock}>
                            <CustomButton
                                onClick={() => setIsOpen(false)}
                                variant="outlined"
                                data-cy="Bncl-DuplicateForm-Cancel"
                            >
                                Cancel
                            </CustomButton>
                            <CustomButton
                                onClick={onSubmit}
                                color="primary"
                                variant="contained"
                                data-cy="Bncl-DuplicateForm-Submit"
                            >
                                Duplicate
                            </CustomButton>
                        </Box>
                    </>
                </LazyModal>
            </Suspense>
            <AppBar position="sticky" color="secondary" data-cy="Bncl-CampaignNav">
                <Toolbar variant="dense">
                    <Box mr={2} py={0.5} ml="auto">
                        <CampaignTitle title={campaign.name} uuid={campaign.campaignId} />
                    </Box>

                    <Box mr={2} py={0.5}>
                        <CustomButton
                            color="primary"
                            variant="contained"
                            onClick={() => setIsOpen(true)}
                            data-cy="Bncl-CampaignNav-DuplicateBtn"
                        >
                            Duplicate Campaign
                        </CustomButton>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
}
