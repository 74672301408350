import { AppBar, Paper, Tab, Tabs } from '@material-ui/core';
import { COLORS } from 'common/constants';
import TabPanel from 'components/common/TabPanel/TabPanel';
import { VENDOR_ORDER, Vendor } from 'models/vendor';
import React, { Fragment, useMemo } from 'react';

export interface VendorTabsProps {
    vendors: Vendor[];
    currentVendor: Vendor;
    children: React.ReactElement;
    setCurrentVendor: Function;
    passVendorId?: boolean;
    vendorsWithError?: number[];
    customLabels?: {
        [vendorName: string]: React.ReactNode;
    };
}

const VendorTabs: React.FC<VendorTabsProps> = ({
    vendors: srcVendors,
    children,
    currentVendor,
    setCurrentVendor,
    passVendorId = false,
    vendorsWithError = [],
    customLabels = {},
}) => {
    const vendors = useMemo(() => {
        const result = [...srcVendors]; // need copy because it can be redux array, that you cannot mutate outside dispatch
        result.sort((a, b) => {
            return VENDOR_ORDER[a.country] - VENDOR_ORDER[b.country];
        });
        return result;
    }, [srcVendors]);

    const handleChange = (event: React.ChangeEvent<{}>, newVendorId: number) => {
        const newVendor = vendors.find(vendor => {
            return vendor.id === newVendorId;
        });
        setCurrentVendor(newVendor);
    };
    const currentHasError = !!vendorsWithError.find(x => x == currentVendor.id);
    const indicatorStyle = {
        backgroundColor: COLORS.red.color,
    };
    const tabProps = { style: currentHasError ? indicatorStyle : {} };

    return (
        <Fragment>
            <AppBar position="static" color="default">
                <Paper square>
                    <Tabs
                        value={currentVendor.id}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={handleChange}
                        aria-label="Vendor Selection"
                        data-cy="VendorTabs"
                        TabIndicatorProps={tabProps}
                    >
                        {vendors.map(vendor => {
                            const hasError = !!vendorsWithError.find(x => x == vendor.id);
                            const style = hasError ? { color: COLORS.red.color } : {};
                            return (
                                <Tab
                                    key={vendor.id}
                                    label={customLabels[vendor.name] || vendor.name}
                                    value={vendor.id}
                                    data-cy={`VendorTabs-${vendor.country}`}
                                    style={style}
                                />
                            );
                        })}
                    </Tabs>
                </Paper>
            </AppBar>
            {vendors.map(vendor => (
                <TabPanel key={vendor.id} value={currentVendor.id} index={vendor.id}>
                    {passVendorId ? React.cloneElement(children, { vendorId: vendor.id }) : children}
                </TabPanel>
            ))}
        </Fragment>
    );
};

export default VendorTabs;
