import { Box, Checkbox, FormControlLabel, FormGroup, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useVendorPickerState } from './useVendorPicker';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkboxLabel: {
            marginRight: 0,
            marginLeft: 0,
            '& span': {
                lineHeight: 0.5,
                fontSize: '0.7rem',
            },
            '& .MuiFormControlLabel-label': {
                padding: '0 9px',
            },
        },
        vendorsControl: {
            display: 'inline-block',
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        vendorsControlLabel: {
            color: theme.palette.text.secondary,
            marginLeft: theme.spacing(1),
        },
    }),
);

type VendorCheckboxPickerProps = {
    isDisabled: (vendorName: string) => boolean;
    cy: string;
    title?: string;
} & Pick<useVendorPickerState, 'allVendors' | 'pickedVendors' | 'onVendorCheck'>;

export default function VendorCheckboxPicker({
    isDisabled,
    allVendors,
    pickedVendors,
    onVendorCheck,
    cy,
    title,
}: VendorCheckboxPickerProps): React.ReactElement {
    const classes = useStyles();

    return (
        <Box className={classes.vendorsControl}>
            <Typography className={classes.vendorsControlLabel} component="label">
                {title || 'Vendors'}
            </Typography>
            <FormGroup row={true}>
                {allVendors.map((vendor, index) => (
                    <FormControlLabel
                        key={index}
                        className={classes.checkboxLabel}
                        control={
                            <Checkbox
                                color="primary"
                                id={`${vendor.id}`}
                                onChange={(ev, checked) => onVendorCheck(vendor, checked)}
                                checked={!!pickedVendors.find(item => item.name === vendor.name)}
                                disabled={isDisabled(vendor.name)}
                                data-cy={`${cy}${vendor.country}`}
                            />
                        }
                        label={vendor.country}
                        labelPlacement="bottom"
                    />
                ))}
            </FormGroup>
        </Box>
    );
}
