import {
    KeyboardDateTimePicker as MuiKeyboardDateTimePicker,
    KeyboardDateTimePickerProps as MuiKeyboardDateTimePickerProps,
} from '@material-ui/pickers';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { isValidDate } from 'helpers/dateHelper';

const useStyles = makeStyles(() =>
    createStyles({
        tzHints: {
            fontSize: '0.7rem',
        },
    }),
);

export type KeyboardDateTimePickerProps = MuiKeyboardDateTimePickerProps & {
    showTzHints?: boolean;
};

export const KeyboardDateTimePicker = ({
    showTzHints = false,
    value,
    label,
    helperText,
    ...props
}: KeyboardDateTimePickerProps): React.ReactElement => {
    const classes = useStyles();
    const [tzHints, setTzHints] = useState<string | React.ReactNode>('');

    if (showTzHints) {
        label += ' (your local time)';

        useEffect(() => {
            const date = (typeof value === 'string' ? new Date(value) : value) as Date;
            if (!isValidDate(date)) {
                setTzHints('');
                return;
            }

            const wetTime = date.toLocaleString('en-UK', { timeZone: 'Europe/London' }).slice(0, -3);
            const cetTime = date.toLocaleString('en-UK', { timeZone: 'Europe/Amsterdam' }).slice(0, -3);

            setTzHints(
                <span className={classes.tzHints}>
                    <b>WET:</b> {wetTime} | <b>CET:</b> {cetTime}
                </span>,
            );
        }, [value]);
    }

    return <MuiKeyboardDateTimePicker value={value} label={label} helperText={helperText || tzHints} {...props} />;
};
