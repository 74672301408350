import React from 'react';
import 'polyfills';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/common/App/App';
// import * as serviceWorker from './serviceWorker';
import { activeDirectoryServiceInstance } from 'services/activeDirectoryService';

activeDirectoryServiceInstance.login(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
});

// serviceWorker.unregister();
