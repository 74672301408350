import { IconButton, IconButtonProps, makeStyles, PropTypes, Theme } from '@material-ui/core';
import * as React from 'react';

type ExtraProps = {
    variant?: 'contained' | 'outlined' | 'text';
    color?: PropTypes.Color | 'error' | 'success';
};

type ColoredIconButtonType = {
    children?: any;
    className?: string;
} & ExtraProps &
    Omit<IconButtonProps, 'color'>;

const useStyles = makeStyles((theme: Theme) => ({
    button: ({ color, variant }: ExtraProps) => {
        const paletteType = color === undefined || color === 'inherit' || color === 'default' ? 'primary' : color;
        const palette = theme.palette[paletteType];

        const overrides: any = {};
        if (variant === 'contained') {
            overrides.backgroundColor = palette.main;
            overrides.color = palette.contrastText;
            overrides['&:hover'] = {
                backgroundColor: palette.dark,
            };
        }

        if (variant === 'outlined') {
            overrides.border = `1px solid ${palette.main}`;
            overrides.color = palette.main;
            overrides['&[disabled]'] = {
                borderColor: theme.palette.grey[400],
                cursor: 'default',
            };
        }

        return overrides;
    },
}));

const extraColors = ['error', 'success'];

export default function ColoredIconButton({
    variant,
    color,
    children,
    className = '',
    ...props
}: ColoredIconButtonType): React.ReactElement {
    const classes = useStyles({ variant, color });

    return (
        <IconButton
            className={`${classes.button} ${className}`}
            color={extraColors.includes(color as string) ? undefined : (color as PropTypes.Color)}
            {...props}
        >
            {children}
        </IconButton>
    );
}
