import VendorTabs from 'components/campaigns/VendorTabs/VendorTabs';
import * as React from 'react';
import { useVendorPickerState } from './useVendorPicker';

type VendorPickedTabsProps = {
    children: React.ReactElement;
    customLabels?: {
        [vendorName: string]: React.ReactNode;
    };
} & Pick<useVendorPickerState, 'currentVendor' | 'setCurrentVendor' | 'pickedVendors'>;

export default function VendorPickedTabs({
    children,
    currentVendor,
    setCurrentVendor,
    pickedVendors,
    customLabels,
}: VendorPickedTabsProps): React.ReactElement {
    return (
        <>
            {!!pickedVendors.length && currentVendor && (
                <VendorTabs
                    vendors={pickedVendors}
                    currentVendor={currentVendor}
                    setCurrentVendor={setCurrentVendor}
                    customLabels={customLabels}
                >
                    {children}
                </VendorTabs>
            )}
        </>
    );
}
