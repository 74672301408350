import { Box, Grid, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
    BnclCampaignDto,
    CampaignCategory,
    CampaignCategoryHumanizedNames,
    VendorCampaignStatus,
} from 'apis/bnclCampaign';
import { FormikDateTimePicker } from 'components/common/Form/FormikDateTimePicker';
import { FormikRadioGroup } from 'components/common/Form/FormikRadioGroup';
import { FormikTextField } from 'components/common/Form/FormikTextField';
import { isPast } from 'date-fns';
import { FormikErrors, useFormik } from 'formik';
import { isValidDate, parseAtomToDate } from 'helpers/dateHelper';
import _ from 'lodash';
import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle } from 'react';

type FormType = {
    name: string;
    endDate: Date;
    category: CampaignCategory;
};

type BnclCampaignFormProps = {
    campaign: BnclCampaignDto | null;
    children: React.ReactElement;
};

export interface BnclCampaignFormHandlers {
    /**
     * returns void if invalid
     */
    submit: () => Promise<FormType | void>;
    isModified: () => boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(3),
            backgroundColor: theme.palette.secondary.light,
        },
    }),
);

const validate = (values: FormType) => {
    const errors: FormikErrors<FormType> = {};

    if (!values.name) {
        errors.name = 'Enter campaign name';
    }

    if (!values.endDate) {
        errors.endDate = 'Enter end date';
    } else if (!isValidDate(values.endDate)) {
        errors.endDate = 'End date should be a valid date';
    } else if (isPast(values.endDate)) {
        errors.endDate = 'End date should not be in the past';
    }

    if (!values.category) {
        errors.category = 'Select a category';
    } else if (!Object.values(CampaignCategory).includes(values.category)) {
        errors.category = 'Invalid category';
    }

    return errors;
};

const campaignToForm = (campaign: BnclCampaignDto): FormType => ({
    name: campaign.name,
    endDate: parseAtomToDate(campaign.endDate),
    category: campaign.category,
});

function BnclCampaignForm({ campaign, children }: BnclCampaignFormProps, ref): React.ReactElement {
    const classes = useStyles();

    const endDate = new Date();
    endDate.setHours(23);
    endDate.setMinutes(59);

    const formik = useFormik<FormType>({
        initialValues: {
            name: '',
            endDate,
            category: CampaignCategory.MERCHANDISING,
        },
        onSubmit: async _ => _,
        validate,
    });

    const isDisabled = !!(campaign && campaign.vendorCampaignList.find(vc => vc.status !== VendorCampaignStatus.DRAFT));

    useImperativeHandle(
        ref,
        (): BnclCampaignFormHandlers => ({
            submit: async () => {
                const values = await formik.submitForm();
                return values;
            },
            isModified: () => {
                const initialValues = campaign ? campaignToForm(campaign) : formik.initialValues;
                return !_.isEqual(initialValues, formik.values);
            },
        }),
    );

    useEffect(() => {
        if (!campaign) return;
        formik.setValues(campaignToForm(campaign));
    }, [campaign]);

    return (
        <Box className={classes.container}>
            <form data-cy="Bncl-Campaign-Form">
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormikTextField
                            label="Campaign name"
                            name="name"
                            fullWidth
                            required
                            formik={formik}
                            disabled={isDisabled}
                            data-cy="Bncl-Campaign-Name"
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <FormikRadioGroup
                            label="Campaign category(s)"
                            name="category"
                            formik={formik}
                            options={Object.entries(CampaignCategoryHumanizedNames).map(([value, label]) => ({
                                value,
                                label,
                            }))}
                            row
                            disabled={isDisabled}
                            data-cy="Bncl-Campaign-Categories"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormikDateTimePicker
                            label="Campaign end date"
                            name="endDate"
                            fullWidth
                            formik={formik}
                            disabled={isDisabled}
                            data-cy="Bncl-Campaign-EndDate"
                            showTzHints
                        />
                    </Grid>
                </Grid>
            </form>
            {children}
        </Box>
    );
}

export default forwardRef(BnclCampaignForm);
