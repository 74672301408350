export const productsPerArticletype = {
    Photobooks: [
        'PAP_324',
        'PAP_347',
        'PAP_130',
        'PAP_354',
        'PAP_348',
        'PAP_194',
        'PAP_360',
        'PAP_355',
        'PAP_201',
        'PAP_202',
        'PAP_324_COVER',
        'PAP_347_COVER',
        'PAP_130_COVER',
        'PAP_354_COVER',
        'PAP_348_COVER',
        'PAP_194_COVER',
        'PAP_360_COVER',
        'PAP_355_COVER',
        'PAP_201_COVER',
        'PAP_202_COVER',
        'PAP_606_COVER',
        'PAP_349',
        'PAP_109',
        'PAP_204',
        'PAP_205',
        'PAP_203',
        'PAP_350',
    ],
    PaddedPhotoBook: ['PAP_193', 'PAP_318', 'PAP_319', 'PAP_323', 'PAP_328'],
    CutoutPhotoBook: ['PAP_321'],
    MomentsPhotoBook: ['PAP_206'],
    Canvas: [
        'PAP_403',
        'PAP_404',
        'PAP_405',
        'PAP_418',
        'PAP_408',
        'PAP_409',
        'PAP_410',
        'PAP_411',
        'PAP_412',
        'PAP_413',
        'PAP_414',
        'PAP_415',
        'PAP_417',
        'PAP_420',
        'PAP_421',
        'PAP_422',
        'PAP_423',
        'PAP_424',
        'PAP_425',
        'PAP_426',
        'PAP_427',
        'PAP_428',
        'PAP_416',
        'PAP_419',
        'PAP_429',
        'PAP_430',
        'PAP_431',
        'PAP_432',
        'PAP_433',
        'PAP_434',
        'PAP_435',
        'PAP_436',
        'PAP_437',
        'PAP_438',
        'PAP_439',
        'PAP_440',
        'PAP_441',
        'PAP_442',
        'PAP_443',
        'PAP_444',
        'PAP_445',
        'PAP_446',
        'PAP_447',
        'PAP_448',
        'PAP_449',
        'PAP_700',
        'PAP_701',
        'PAP_702',
        // NOTE 4CM canvases from here (should be moved to different entry when it is officially introduced)
        'PAP_730',
        'PAP_731',
        'PAP_732',
        'PAP_733',
        'PAP_734',
        'PAP_735',
        'PAP_736',
        'PAP_737',
        'PAP_738',
        'PAP_739',
        'PAP_740',
        'PAP_741',
        'PAP_742',
        'PAP_743',
        'PAP_744',
        'PAP_745',
        'PAP_746',
        'PAP_747',
        'PAP_748',
        'PAP_749',
        'PAP_750',
        'PAP_751',
        'PAP_752',
        'PAP_753',
        'PAP_754',
        'PAP_755',
        'PAP_756',
        'PAP_757',
        'PAP_758',
        'PAP_759',
        'PAP_760',
        'PAP_761',
        'PAP_762',
        'PAP_763',
        'PAP_764',
        'PAP_765',
        'PAP_766',
        'PAP_767',
        'PAP_768',
        'PAP_769',
        'PAP_770',
        'PAP_771',
        'PAP_772',
        'PAP_773',
        'PAP_774',
        'PAP_775',
        'PAP_776',
        'PAP_777',
    ],
    Perspex: [
        'PAP_450',
        'PAP_451',
        'PAP_452',
        'PAP_453',
        'PAP_454',
        'PAP_455',
        'PAP_470',
        'PAP_471',
        'PAP_472',
        'PAP_473',
        'PAP_474',
        'PAP_475',
        'PAP_456',
        'PAP_476',
        'PAP_480',
        'PAP_481',
        'PAP_482',
        'PAP_483',
        'PAP_484',
        'PAP_485',
        'PAP_486',
        'PAP_487',
        'PAP_457',
        'PAP_458',
        'PAP_459',
        'PAP_460',
        'PAP_461',
        'PAP_462',
        'PAP_463',
        'PAP_464',
        'PAP_465',
        'PAP_466',
        'PAP_467',
        'PAP_468',
        'PAP_469',
        'PAP_477',
        'PAP_478',
        'PAP_479',
        'PAP_488',
        'PAP_489',
        'PAP_490',
        'PAP_491',
        'PAP_492',
        'PAP_493',
        'PAP_494',
        'PAP_495',
        'PAP_496',
        'PAP_497',
    ],
    Aluminium: [
        'PAP_510',
        'PAP_511',
        'PAP_512',
        'PAP_513',
        'PAP_514',
        'PAP_515',
        'PAP_516',
        'PAP_517',
        'PAP_518',
        'PAP_519',
        'PAP_520',
        'PAP_521',
        'PAP_522',
        'PAP_523',
        'PAP_524',
        'PAP_525',
        'PAP_526',
        'PAP_527',
        'PAP_528',
        'PAP_529',
        'PAP_530',
        'PAP_531',
        'PAP_500',
        'PAP_504',
        'PAP_505',
        'PAP_506',
        'PAP_507',
        'PAP_508',
        'PAP_509',
        'PAP_532',
        'PAP_533',
        'PAP_534',
        'PAP_535',
        'PAP_536',
        'PAP_537',
        'PAP_538',
        'PAP_539',
        'PAP_499',
        'PAP_680',
        'PAP_681',
        'PAP_682',
        'PAP_683',
        'PAP_684',
        'PAP_685',
        'PAP_686',
        'PAP_687',
        'PAP_688',
        'PAP_498',
        // NOTE brushed aluminium (aluminiumButler) from here, should be moved to different entry when it is officially introduced
        'PAP_851',
        'PAP_852',
        'PAP_853',
        'PAP_854',
        'PAP_855',
        'PAP_856',
        'PAP_857',
        'PAP_858',
        'PAP_859',
        'PAP_860',
        'PAP_861',
        'PAP_862',
        'PAP_863',
        'PAP_864',
        'PAP_865',
        'PAP_866',
        'PAP_867',
        'PAP_868',
        'PAP_869',
        'PAP_870',
        'PAP_871',
        'PAP_872',
        'PAP_873',
        'PAP_874',
        'PAP_875',
        'PAP_876',
        'PAP_877',
        'PAP_878',
        'PAP_879',
        'PAP_880',
        'PAP_881',
        'PAP_882',
        'PAP_883',
        'PAP_884',
        'PAP_885',
        'PAP_886',
        'PAP_887',
        'PAP_888',
        'PAP_889',
        'PAP_890',
        'PAP_891',
        'PAP_892',
        'PAP_893',
        'PAP_894',
        'PAP_895',
        'PAP_896',
        'PAP_897',
        'PAP_850',
    ],
    Forex: [
        'PAP_540',
        'PAP_541',
        'PAP_542',
        'PAP_543',
        'PAP_544',
        'PAP_545',
        'PAP_546',
        'PAP_547',
        'PAP_548',
        'PAP_549',
        'PAP_562',
        'PAP_563',
        'PAP_552',
        'PAP_553',
        'PAP_554',
        'PAP_555',
        'PAP_556',
        'PAP_557',
        'PAP_558',
        'PAP_559',
        'PAP_560',
        'PAP_561',
        'PAP_564',
        'PAP_565',
        'PAP_566',
        'PAP_567',
        'PAP_568',
        'PAP_569',
        'PAP_592',
        'PAP_593',
        'PAP_594',
        'PAP_595',
        'PAP_596',
        'PAP_597',
        'PAP_598',
        'PAP_599',
        'PAP_600',
        'PAP_604',
        'PAP_690',
        'PAP_691',
        'PAP_692',
        'PAP_693',
        'PAP_694',
        'PAP_695',
        'PAP_696',
        'PAP_697',
        'PAP_698',
        'PAP_699',
    ],
    Poster: [
        'PAP_610',
        'PAP_611',
        'PAP_612',
        'PAP_613',
        'PAP_614',
        'PAP_615',
        'PAP_616',
        'PAP_617',
        'PAP_618',
        'PAP_619',
        'PAP_620',
        'PAP_621',
        'PAP_622',
        'PAP_623',
        'PAP_624',
        'PAP_625',
        'PAP_626',
        'PAP_627',
        'PAP_628',
        'PAP_629',
        'PAP_630',
        'PAP_631',
        'PAP_632',
        'PAP_634',
        'PAP_635',
        'PAP_636',
        'PAP_637',
        'PAP_638',
        'PAP_639',
        'PAP_640',
        'PAP_641',
        'PAP_642',
        'PAP_643',
        'PAP_644',
        'PAP_645',
        'PAP_646',
        'PAP_647',
        'PAP_648',
        'PAP_649',
        'PAP_670',
        'PAP_671',
        'PAP_672',
        'PAP_673',
        'PAP_674',
        'PAP_675',
        'PAP_676',
        'PAP_677',
        'PAP_678',
    ],
    Calendars: [
        'PAP_653',
        'PAP_658',
        'PAP_659',
        'PAP_660',
        'PAP_665',
        'PAP_666',
        'PAP_667',
        'PAP_668',
        'PAP_962',
        'PAP_963',
        'PAP_964',
        'PAP_965',
    ],
    Prints: ['PAP_910', 'PAP_911', 'PAP_914', 'PAP_915'],
    LoveMug: ['PAP_722', 'PAP_723'],
    MagicMug: ['PAP_724', 'PAP_725'],
    Mugs: ['PAP_720', 'PAP_721'],
    Cards: [
        'PAP_940',
        'PAP_941',
        'PAP_942',
        'PAP_943',
        'PAP_944',
        'PAP_945',
        'PAP_950',
        'PAP_951',
        'PAP_952',
        'PAP_953',
        'PAP_954',
        'PAP_955',
    ],
    Magnets: ['PAP_810', 'PAP_811'],
    Jigsaw: ['PAP_970', 'PAP_971', 'PAP_972', 'PAP_973', 'PAP_974', 'PAP_975'],
    Cushions: ['PAP_920', 'PAP_921', 'PAP_922'],
    Photoblock: [
        'PAP_780',
        'PAP_781',
        'PAP_782, PAP_783, PAP_784, PAP_785',
        'PAP_786',
        'PAP_787, PAP_788, PAP_789, PAP_790',
    ],
    StudentPlakat: ['PAP_901', 'PAP_902', 'PAP_903'],
};
