import { Vendor as CatalogVendor } from '@albelli/ecom-promotions-editor';
import { all, call, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { getDiscountByCode } from 'apis/promotionsApi';
import {
    requestedOfferCreation,
    requestedOfferUpdate,
    requestOffers,
} from 'application/campaignForm/offerCreate/offerCreateActions';
import {
    doCreateOffer,
    doDeleteOffer,
    doDeleteOfferTemporary,
} from 'application/campaignForm/offerCreate/offerCreateSaga';
import { getDiscountCodes, getOffers } from 'application/selectors';
import { createGenericReducer } from 'common/genericReducer';
import { doCall } from 'common/genericSaga';
import { RequestInfo } from 'common/genericTypes';
import { RequestState } from 'common/RequestState';
import { prepareOffers } from 'components/campaigns/CampaignForm/CampaignDiscountCodeSelection/DiscountDefinitionMapper';
import {
    MappedDefinition,
    OfferCreationMode,
    parseDefinition,
} from 'components/campaigns/CampaignForm/CampaignDiscountCodeSelection/DiscountDefinitionParser';
import { parseISO } from 'date-fns';
import DiscountDefinitionParseError from 'errors/DiscountDefinitionParseError';
import DiscountValidationError from 'errors/DiscountValidationError';
import { setStandardSecondValue } from 'helpers/dateHelper';
import { DiscountDefinition, DiscountStatus, DiscountUsageType } from 'models/discounts/discount';
import { isOfferEqual, Offer } from 'models/offer';
import { Vendor } from 'models/vendor';
import reduceReducers from 'reduce-reducers';
import {
    DISCOUNT_CODE_DELETE_FAILED,
    DISCOUNT_CODE_DELETE_RECEIVED,
    DISCOUNT_CODE_DELETE_REQUESTED,
    DISCOUNT_CODE_PARSE_FAILED,
    DISCOUNT_CODE_PARSE_RECEIVED,
    DISCOUNT_CODE_PARSE_REQUESTED,
    DISCOUNT_CODE_PARSE_RESET,
    OFFER_DISCOUNT_CODE_UPDATE_FAILED,
    OFFER_DISCOUNT_CODE_UPDATE_RECEIVED,
    OFFER_DISCOUNT_CODE_UPDATE_REQUESTED,
} from './offerCreateTypes';

export interface DiscountCodeDetails {
    vendor_id: number;
    validate: boolean;
    valid: boolean;
    exists: boolean;
    promo_code: string;
    discountDefinition: DiscountDefinition;
    parsedDefinition: Record<string, any>;
    errors: string[];
    offerCreationMode: OfferCreationMode;
    didGeneratedOffers: boolean;
}
interface SaveDiscountCodeAction {
    type: string;
    discountCode: string;
    campaignId: number;
    campaignStartDate: string;
    campaignEndDate: string;
    catalogVendors: CatalogVendor[];
    campaignManagerVendors: Vendor[];
    generateOffers: boolean;
    validate: boolean;
    vendor_id: number;
}

interface DeleteDiscountCodeAction {
    type: string;
    vendorId: number;
    discountCode: string;
}

interface UpdateOffersDiscountCodeAction {
    type: string;
    discountCode: string;
    currentVendorId: number;
    campaignId: number;
    campaignStartDate: string;
    campaignEndDate: string;
}

export const requestParseDiscountCode = (
    discountCode: string,
    campaignId: number,
    campaignStartDate: string,
    campaignEndDate: string,
    catalogVendors: CatalogVendor[],
    campaignManagerVendors: Vendor[],
    generateOffers: boolean,
    validate: boolean,
    vendor_id: number,
): SaveDiscountCodeAction => ({
    type: DISCOUNT_CODE_PARSE_REQUESTED,
    discountCode,
    campaignId,
    campaignStartDate,
    campaignEndDate,
    catalogVendors,
    campaignManagerVendors,
    generateOffers,
    validate,
    vendor_id,
});
export const receivedParseDiscountCode = payload => ({
    type: DISCOUNT_CODE_PARSE_RECEIVED,
    payload,
});

export const resetParseDiscountCode = () => ({
    type: DISCOUNT_CODE_PARSE_RESET,
});
export const errorParseDiscountCode = requestAction => ({
    type: DISCOUNT_CODE_PARSE_FAILED,
    error: new Error('Failed to save Discount Code.'),
    requestAction,
});

export const requestedDeleteDiscountCode = (vendorId: number, discountCode: string): DeleteDiscountCodeAction => ({
    type: DISCOUNT_CODE_DELETE_REQUESTED,
    vendorId,
    discountCode,
});

export const receivedDeleteDiscountCode = payload => ({
    type: DISCOUNT_CODE_DELETE_RECEIVED,
    payload,
});

export const errorDeleteDiscountCode = requestAction => ({
    type: DISCOUNT_CODE_DELETE_FAILED,
    error: new Error('Failed to delete Discount Code.'),
    requestAction,
});

export const requestOffersDiscountCodeUpdate = (
    discountCode: string,
    currentVendorId: number,
    campaignId: number,
    campaignStartDate: string,
    campaignEndDate: string,
): UpdateOffersDiscountCodeAction => ({
    type: OFFER_DISCOUNT_CODE_UPDATE_REQUESTED,
    discountCode,
    currentVendorId,
    campaignId,
    campaignStartDate,
    campaignEndDate,
});
export const receivedOffersDiscountCodeUpdate = payload => ({
    type: OFFER_DISCOUNT_CODE_UPDATE_RECEIVED,
    payload,
});
export const errorOffersDiscountCodeUpdate = requestAction => ({
    type: OFFER_DISCOUNT_CODE_UPDATE_FAILED,
    error: new Error('Failed to update offers discount code'),
    requestAction,
});

/**
 * Reducer
 */
export const discountCodes = reduceReducers(
    createGenericReducer(
        DISCOUNT_CODE_PARSE_REQUESTED,
        DISCOUNT_CODE_PARSE_RECEIVED,
        DISCOUNT_CODE_PARSE_FAILED,
        DISCOUNT_CODE_PARSE_RESET,
        null,
        { data: [], requestState: RequestState.Finished },
    ),
    createGenericReducer(DISCOUNT_CODE_DELETE_REQUESTED, DISCOUNT_CODE_DELETE_RECEIVED, DISCOUNT_CODE_DELETE_FAILED),
);

/**
 * SAGA
 */

const validateDiscountCode = (
    discountDefinition: DiscountDefinition,
    campaignActivationDate: string,
    campaignExpirationDate: string,
) => {
    let discountError = '';
    const campaignStartDate = setStandardSecondValue(parseISO(campaignActivationDate));
    const campaignEndDate = setStandardSecondValue(parseISO(campaignExpirationDate));
    const discountStartDate = setStandardSecondValue(parseISO(discountDefinition.validFrom.toString()));
    const discountEndDate = setStandardSecondValue(parseISO(discountDefinition.validTo.toString()));

    if (discountDefinition.usageType === DiscountUsageType.SingleUse) {
        discountError = 'Single use discount codes are not allowed';
    } else if (discountDefinition.status !== DiscountStatus.published) {
        discountError = 'Discount should be published';
    } else if (
        // validFrom and validTo are actually strings so we need to parse them
        discountStartDate > campaignStartDate ||
        discountEndDate < campaignEndDate
    ) {
        discountError = 'Discount should be valid during the campaign start/end dates';
    }

    if (discountError) throw new DiscountValidationError(discountError);
};

const recursiveSearch = (obj: Record<string, any>, map = {}, res: Array<any> = []): any[] => {
    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'object') {
            return recursiveSearch(obj[key], map, res);
        }
        map[obj[key]] = (map[obj[key]] || 0) + 1;
        if (map[obj[key]] === 2) {
            res.push(obj[key]);
        }
    });
    return res;
};

export function* doParseDiscountDefinition(action: SaveDiscountCodeAction, requestInfo: RequestInfo) {
    const errors = new Array<string>();
    let offerCreationMode = OfferCreationMode.Manual;
    let parsedDefinition;
    let exists = false;
    let isValid = false;
    //get definition
    const discountDefinition = yield call(getDiscountByCode, action.discountCode);
    const complementaryFeedback = 'please fill in offer details manually';

    if (discountDefinition) {
        try {
            exists = true;
            //validate discount
            if (action.validate) {
                validateDiscountCode(discountDefinition, action.campaignStartDate, action.campaignEndDate);
            }
            isValid = true;
            // parse definition
            parsedDefinition = manageParsing(action, discountDefinition);
            offerCreationMode = OfferCreationMode.Auto;
            //generate offers
            if (action.generateOffers) {
                const generationErrors = yield* generateOffers(parsedDefinition, action);
                generationErrors.map(e => errors.push(e));
            }
        } catch (error) {
            let message = (error as Error).message;
            if (error instanceof DiscountDefinitionParseError) {
                message += `, ${complementaryFeedback}`;
            }
            errors.push(message);

            if (isValid) {
                offerCreationMode = OfferCreationMode.Manual;
            }
        }
    } else {
        errors.push(`Discount could not be found, ${complementaryFeedback}`);
    }

    const parsedDiscount: DiscountCodeDetails = {
        vendor_id: action.vendor_id,
        validate: false,
        valid: isValid,
        exists: exists,
        promo_code: action.discountCode,
        discountDefinition: discountDefinition,
        parsedDefinition: parsedDefinition,
        errors: errors,
        offerCreationMode: offerCreationMode,
        didGeneratedOffers: action.generateOffers,
    };

    return parsedDiscount;
}

function manageParsing(action: SaveDiscountCodeAction, discountDefinition: DiscountDefinition): MappedDefinition {
    let parsedDefinition;
    try {
        parsedDefinition = parseDefinition(action.discountCode, discountDefinition.definition);
    } catch (error) {
        throw new DiscountDefinitionParseError(
            'Discount definition could not be read, please fill in offer details manually',
            error as Error,
        );
    }
    return parsedDefinition;
}

function* generateOffers(parsedDefinition: MappedDefinition, action: SaveDiscountCodeAction) {
    const existingOffers = yield select(getOffers);
    const existingVendorOffers = existingOffers.data
        ? existingOffers.data.filter(offer => offer.vendor_id === action.vendor_id)
        : [];
    const errors = new Array<string>();
    let newOffers = prepareOffers(parsedDefinition, action.campaignId, action.campaignManagerVendors, action.vendor_id);
    const requestInfo = { correlationId: '', includeToken: false };

    //do not add existing offers
    if (newOffers.length && existingVendorOffers.length) {
        existingVendorOffers.map(existingOffer => {
            newOffers = newOffers.filter(newOffer => !isOfferEqual(newOffer, existingOffer));
        });
    }

    if (newOffers.length) {
        for (const offer of newOffers) {
            try {
                yield call(doCreateOffer, requestedOfferCreation(false, '', offer), requestInfo);
            } catch (error) {
                errors.push('Error Generating Offer: ' + (error as Error).message);
            }
        }

        yield put(requestOffers(action.campaignId));
    } else {
        errors.push('No offers available for this vendor');
    }

    return errors;
}

export function* doUpdateOffersDiscountCode(action: UpdateOffersDiscountCodeAction, requestInfo: RequestInfo) {
    const offers: { data: Offer[] } = yield select(getOffers);
    const vendorOffers: Offer[] = offers.data.filter((offer: Offer) => offer.vendor_id === action.currentVendorId);

    //update discount code data
    yield put(
        requestParseDiscountCode(
            action.discountCode,
            action.campaignId,
            action.campaignStartDate,
            action.campaignEndDate,
            [],
            [],
            false,
            false,
            action.currentVendorId,
        ),
    );
    const updatedOffers = { ...offers };
    //update each offer
    for (const offer of vendorOffers) {
        const offerData = { ...offer, promo_code: action.discountCode };
        const elementsIndex = updatedOffers.data.findIndex(element => element.id === offer.id);
        updatedOffers.data[elementsIndex] = Object.assign(updatedOffers.data[elementsIndex], offerData);
        yield put(requestedOfferUpdate(offerData));
    }

    yield put(receivedOffersDiscountCodeUpdate(updatedOffers));
    yield put(requestOffers(action.campaignId));
}

export function* doParseDiscountCode(action: SaveDiscountCodeAction, requestInfo: RequestInfo) {
    const discountCodes: { data: Array<DiscountCodeDetails> } = yield select(getDiscountCodes);
    const updatedDiscounts: Array<DiscountCodeDetails> = [...discountCodes.data];
    const discountCodeDetails = yield call(doParseDiscountDefinition, action, requestInfo);
    const elementsIndex = updatedDiscounts.findIndex(element => element.vendor_id === action.vendor_id);
    if (elementsIndex >= 0) {
        updatedDiscounts[elementsIndex] = { ...updatedDiscounts[elementsIndex], ...discountCodeDetails };
    } else {
        updatedDiscounts.push(discountCodeDetails);
    }
    yield put(receivedParseDiscountCode({ data: updatedDiscounts }));
}

export function* doDeleteOffersWithDiscountCode(vendorId: number, discountCode: string, requestInfo: RequestInfo) {
    const offers = yield select(getOffers);
    if (offers) {
        const vendorOffers = offers.data.filter(
            offer => offer.vendor_id === vendorId && offer.promo_code === discountCode,
        );

        for (const offerToDelete of vendorOffers) {
            if (offerToDelete.temporaryId) {
                yield call(
                    doDeleteOfferTemporary,
                    { type: '', temporaryOfferId: offerToDelete.temporaryId },
                    requestInfo,
                );
            } else {
                yield call(doDeleteOffer, { type: '', offerId: offerToDelete.id }, requestInfo);
            }
        }
    }
}

export function* doDeleteDiscountCode(action: DeleteDiscountCodeAction, requestInfo: RequestInfo) {
    const discountCodes: { data: Array<DiscountCodeDetails> } = yield select(getDiscountCodes);
    let updatedDiscounts: Array<DiscountCodeDetails> = [...discountCodes.data];
    updatedDiscounts = updatedDiscounts.filter(element => element.vendor_id !== action.vendorId);

    yield call(doDeleteOffersWithDiscountCode, action.vendorId, action.discountCode, requestInfo);
    yield put(receivedDeleteDiscountCode({ data: updatedDiscounts }));
}

export function* watchDiscountCodes() {
    yield all([
        takeLatest(
            OFFER_DISCOUNT_CODE_UPDATE_REQUESTED,
            doCall(doUpdateOffersDiscountCode, OFFER_DISCOUNT_CODE_UPDATE_FAILED),
        ),
        takeEvery(DISCOUNT_CODE_PARSE_REQUESTED, doCall(doParseDiscountCode, DISCOUNT_CODE_PARSE_FAILED)),
        takeLatest(DISCOUNT_CODE_DELETE_REQUESTED, doCall(doDeleteDiscountCode, DISCOUNT_CODE_DELETE_FAILED)),
    ]);
}
