import { createStyles, makeStyles, TableCell, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { CampaignStatus } from 'models/campaign';
import { DiscountStatus } from 'models/discounts/discount';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            width: '4px',
            padding: '0px',
        },
        draft: {
            backgroundColor: theme.palette.warning.light,
        },
        scheduled: {
            backgroundColor: theme.palette.success.light,
        },
        published: {
            backgroundColor: theme.palette.success.main,
        },
        expired: {
            backgroundColor: theme.palette.info.light,
        },
        cancelled: {
            backgroundColor: theme.palette.error.light,
        },
    }),
);
export interface StatusBadgeProps {
    status: string | DiscountStatus | CampaignStatus;
}

export const TableStatusRow = ({ status }: StatusBadgeProps): React.ReactElement => {
    const classes = useStyles();
    return <TableCell className={clsx(classes.root, classes[status])}>&nbsp;</TableCell>;
};
