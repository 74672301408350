import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { EmailOutlined, FindInPageOutlined, LocalMallOutlined, MonetizationOn, RecentActors } from '@material-ui/icons';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ComputerOutlinedIcon from '@material-ui/icons/ComputerOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import WebIcon from '@material-ui/icons/Web';
import { CampaignChannels } from 'models/campaign';
import { SEOOptions } from 'models/seo';
import { LODOptions } from 'models/lod';
import { routes } from 'routes';

export interface SideNavigationItem {
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    selected?: boolean;
    text: string;
    url: string;
}

export const navItems: Record<string, Record<string, SideNavigationItem>> = {
    [routes.discounts]: {
        manageDiscounts: {
            icon: LocalOfferIcon,
            text: 'Discounts',
            url: '',
        },
        attributionAdd: { icon: AddCircleOutlineOutlinedIcon, text: 'Add Attribution', url: 'attribution/source/add' },
    },
    [routes.vouchers]: {
        bncl: {
            icon: LocalActivityIcon,
            text: 'BNCL Campaigns',
            url: 'bncl',
        },
    },
    [routes.seo]: {
        [SEOOptions.robots]: {
            icon: ComputerOutlinedIcon,
            text: 'Robots',
            url: 'robots',
        },
        [SEOOptions.sitemap]: {
            icon: AccountTreeOutlinedIcon,
            text: 'Sitemap',
            url: 'sitemap',
        },
        [SEOOptions.redirects]: {
            icon: CallSplitIcon,
            text: 'Redirects',
            url: 'redirects',
        },
        [SEOOptions.seoPages]: {
            icon: DescriptionIcon,
            text: 'Pages',
            url: SEOOptions.seoPages,
        },
    },
    [routes.lod]: {
        [LODOptions.settings]: {
            icon: SettingsOutlinedIcon,
            text: 'Settings',
            url: 'settings',
        },
    },
    [routes.campaigns]: {
        [CampaignChannels.merchandising]: {
            icon: LocalMallOutlined,
            text: 'Merch',
            url: CampaignChannels.merchandising,
        },
        [CampaignChannels.crm]: { icon: EmailOutlined, text: 'CRM', url: CampaignChannels.crm },
        [CampaignChannels.sea]: {
            icon: FindInPageOutlined,
            text: 'SEA (& Shopping)',
            url: CampaignChannels.sea,
        },
        [CampaignChannels.sea_exp]: {
            icon: FindInPageOutlined,
            text: 'SEA Experiments',
            url: CampaignChannels.sea_exp,
        },
        [CampaignChannels.display]: { icon: WebIcon, text: 'Display (& Shopping)', url: CampaignChannels.display },
        [CampaignChannels.display_exp]: {
            icon: WebIcon,
            text: 'Display Experiments',
            url: CampaignChannels.display_exp,
        },
        [CampaignChannels.partner]: { icon: RecentActors, text: 'Partner', url: CampaignChannels.partner },
        [CampaignChannels.affiliate]: {
            icon: MonetizationOn,
            text: 'Affiliate',
            url: CampaignChannels.affiliate,
        },
    },
};

export const generateSideNavItems = (baseUrl: string): SideNavigationItem[] | [] => {
    if (navItems[baseUrl]) {
        return Object.keys(navItems[baseUrl]).map(key => {
            const item = navItems[baseUrl][key];
            const url = `${baseUrl}/${item.url}`;
            const itemProps: SideNavigationItem = { ...item, url: url, selected: false };

            return itemProps;
        });
    } else {
        return [];
    }
};
