/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createGenericReducer } from 'common/genericReducer';
import { doCall } from 'common/genericSaga';
import { RequestInfo } from 'common/genericTypes';
import { Vendor, VENDOR_ORDER } from 'models/vendor';
import { Reducer } from 'redux';
import { all, call, put, takeLeading } from 'redux-saga/effects';
import { getVendors } from '../../apis/publicConfig';
import { getAppConfig } from '../../services/configService';

export const VENDORS_REQUESTED = 'VENDORS_REQUESTED';
export const VENDORS_RECEIVED = 'VENDORS_RECEIVED';
export const VENDORS_REQUEST_FAILED = 'VENDORS_REQUEST_FAILED';

export const requestVendors = () => ({
    type: VENDORS_REQUESTED,
});
export const receiveVendors = payload => ({
    type: VENDORS_RECEIVED,
    payload,
});
export const errorVendors = requestAction => ({
    type: VENDORS_REQUEST_FAILED,
    error: new Error('Failed to retrieve vendors.'),
    requestAction,
});

export const getVendorsReducer: Reducer<{ data: Vendor[]; requestState: string }> = createGenericReducer(
    VENDORS_REQUESTED,
    VENDORS_RECEIVED,
    VENDORS_REQUEST_FAILED,
);

export function* doRequestVendors(action, requestInfo: RequestInfo) {
    const vendors: Vendor[] = yield call(getVendors, {});

    let filteredVendors = vendors;
    if (getAppConfig().Env === 'prd') {
        filteredVendors = vendors.filter(vendor => vendor.isLive !== false);
    }

    filteredVendors.sort((a, b) => {
        return VENDOR_ORDER[a.country] - VENDOR_ORDER[b.country];
    });

    yield put(receiveVendors({ data: filteredVendors }));
}

export function* watchVendorsStore() {
    yield all([takeLeading(VENDORS_REQUESTED, doCall(doRequestVendors, VENDORS_REQUEST_FAILED))]);
}
