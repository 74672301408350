import { createTheme, Theme, withStyles } from '@material-ui/core/styles';

export const CustomColors = {
    background: '#f4f6f8',
    heading: '#38637e',
    text: {
        light: '#333',
        link: '#38637E',
    },
    navHover: 'rgba(216, 216, 216, 0.5)',
    subTitle: '#38637e',
    border: '#ccc',
};

// To extend this, check out:
// https://material-ui.com/customization/default-theme/
export const CustomTheme = createTheme({
    palette: {
        primary: {
            main: '#003952',
        },
        secondary: {
            light: '#fff',
            main: '#fff',
            contrastText: '#003952',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        background: {
            default: CustomColors.background,
        },
        info: {
            main: '#2D81B0',
        },
    },
});

/**
 * Put global css here
 */
export const GlobalCss = withStyles(({ palette }: Theme) => ({
    // @global is handled by jss-plugin-global.
    '@global': {
        // You should target [class*="MuiButton-root"] instead if you nest themes.
        // '.MuiButton-root': {
        // },
        '.MuiTypography-h1': {
            fontSize: '2em',
        },
        '.MuiTypography-h6': {
            height: '30px',
            fontSize: '16px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            color: palette.primary.main,
        },
        '.MuiTypography-subtitle1': {
            fontSize: '1.1em',
        },
        '.MuiSvgIcon-root': {
            width: '0.75em',
            height: '0.75em',
        },
        '.MuiPaper-elevation4': {
            boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        },
        '.MuiToolbar-dense': {
            height: '65px',
        },
        '.MuiTableSortLabel-active': {
            color: '#fff !important',
            textDecoration: 'underline',
        },
        '.MuiTableSortLabel-icon': {
            color: '#fff !important',
        },
        '.MuiTableSortLabel-root:hover': {
            color: '#fff !important',
            textDecoration: 'underline',
        },
    },
}))(() => null);
