import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Color } from '@material-ui/lab';
import clsx from 'clsx';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.common.white,
            padding: '10px',
            textAlign: 'center',
        },
        message: {
            fontWeight: 'bolder',
            lineHeight: 1.5,
        },
        success: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
        },
        info: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.common.white,
        },
        warning: {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.common.white,
        },
        error: {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.common.white,
        },
    }),
);

export interface AlertProps {
    children: any;
    severity?: Color;
}

const Alert: React.FunctionComponent<AlertProps> = ({ children, severity = 'error' }: AlertProps) => {
    const classes = useStyles();
    return (
        <Box m={1} className={clsx(classes.container, classes[severity])} data-cy="Alert-alertBox">
            <div className={classes.message} data-cy="Alert-alertMessage">
                {children}{' '}
            </div>
        </Box>
    );
};

export default Alert;
