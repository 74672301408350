import { Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import ColoredIconButton from '../../common/ColoredIconButton/ColoredIconButton';
import AppendableAutocomplete from '../../common/Form/AppendableAutocomplete';

type BnclOfferFormProps = {
    formik: any;
    name: string;
    label: string;
    disabled: boolean;
    options: string[];
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addOptionButton: {
            marginTop: theme.spacing(1),
            float: 'right',
        },
        optionItem: {
            display: 'flex',
            marginTop: theme.spacing(2),
        },
        optionItemInput: {
            flex: '20 1 auto',
        },
        optionItemDeleteButton: {
            alignSelf: 'end',
            marginLeft: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

const OptionsEditor = ({ formik, name, label, disabled, options }: BnclOfferFormProps): React.ReactElement => {
    const classes = useStyles();
    return (
        <>
            {formik.values[name].map((item, index) => (
                <div key={index} className={classes.optionItem}>
                    <AppendableAutocomplete
                        value={item}
                        autocompleteProps={{
                            disabled,
                            className: classes.optionItemInput,
                            onInputChange: (event, val) => {
                                const options = [...formik.values[name]];
                                options[index] = val;
                                formik.setFieldValue(name, options);
                            },
                        }}
                        onChange={() => ({})}
                        textFieldProps={{
                            InputLabelProps: {
                                shrink: true,
                            },
                            label: index === 0 ? label : '',
                            'data-cy': `Bncl-OfferForm-${name}`,
                            error: !!formik.touched[name] && !!formik.errors[name],
                            helperText: !!formik.touched[name] && formik.errors[name],
                        }}
                        options={options.map(item => ({ label: item, value: item }))}
                    />
                    {formik.values[name].length > 1 && !disabled && (
                        <ColoredIconButton
                            size="small"
                            variant="outlined"
                            onClick={() => {
                                const options = [...formik.values[name]];
                                options.splice(index, 1);
                                formik.setFieldValue(name, options);
                            }}
                            color="primary"
                            className={classes.optionItemDeleteButton}
                            data-cy={`Bncl-OfferForm-${name}-Delete-Button`}
                        >
                            <DeleteIcon fontSize="small" />
                        </ColoredIconButton>
                    )}
                </div>
            ))}
            {!disabled && (
                <Link
                    component="button"
                    className={classes.addOptionButton}
                    type="button"
                    variant="body2"
                    onClick={() => {
                        formik.setFieldValue(name, [...formik.values[name], '']);
                    }}
                    data-cy={`Bncl-OfferForm-${name}-Add-Button`}
                >
                    + ADD
                </Link>
            )}
        </>
    );
};

export default OptionsEditor;
