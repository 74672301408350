import { Switch, SwitchProps, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { CustomColors } from '../CustomTheme/CustomTheme';

type BigSwitchProps = SwitchProps & {
    classes: ClassNameMap<'root' | 'switchBase' | 'thumb' | 'track' | 'checked' | 'focusVisible'>;
};

// IOS like. almost copy/paste from https://v4.mui.com/components/switches/#customized-switches
const BigSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 2,
        margin: 4,
        overflow: 'visible',
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: CustomColors.heading,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: CustomColors.heading,
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
        boxShadow: '0px 1px 4px 0px #00000074',
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }: BigSwitchProps): React.ReactElement => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default BigSwitch;
