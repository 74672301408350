import { Box, createStyles, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    AccountAddressDto,
    AccountAuditDto,
    AccountDetailsDto,
    AccountStatuses,
    TransactionStatuses,
} from 'apis/b2bAccounts';
import {
    closeAccountDetails,
    loadFullAudit,
    submitAccountStatus,
    WithTransactionStatus,
} from 'application/b2b/accountsSlice';
import CustomButton from 'components/common/CustomButton/CustomButton';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import { Loader } from 'components/common/Loader/Loader';
import { isInProgress } from 'helpers/requestStateHelper';
import React, { ReactElement, Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { humanizeAccountStatus } from '../b2bAccountsHelpers';
import AccountChangesTimeline from './AccountChangesTimeline';
import TextFieldHistory from './AccountModalTextFieldHistory';

const LazyModal = React.lazy(() => import('components/common/Modal/Modal').then(({ Modal }) => ({ default: Modal })));

const VAT_CHECK_LINK = 'https://ec.europa.eu/taxation_customs/vies/#/vat-validation';

const getCountrySpecificPropNames = (addr: AccountAddressDto) => {
    switch (addr.country) {
        case 'FR':
            return {
                address: ['frenchAddress', 'complement1', 'complement2', 'lieuDit'],
            };
        case 'NL':
        case 'BE':
            return {
                address: ['streetName', 'streetNumber', 'streetNumberExt'],
            };
        default:
            // DE, GB, NO, SE
            return {
                address: [
                    // should be this
                    'address1',
                    'address2',
                    // but in reality used this
                    'streetName',
                    'streetNumber',
                    'streetNumberExt',
                ],
            };
    }
};

const useStyles = makeStyles(theme =>
    createStyles({
        title: {
            color: CustomColors.heading,
        },
        boxClass: {
            maxWidth: theme.spacing(110),
            width: '100%',
        },
        inputBlock: {
            backgroundColor: theme.palette.grey[200],
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
        },
        inputRow: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: theme.spacing(2),
            '& > *': {
                flexGrow: 1,
                flexBasis: 0,
            },
            '& + &': {
                marginTop: theme.spacing(2),
            },
        },
        actionsBlock: {
            marginTop: theme.spacing(2),
            textAlign: 'right',
            '& button + button': {
                marginLeft: theme.spacing(2),
            },
        },
    }),
);

const joinAddress = (obj: AccountAddressDto, props: string[]) => {
    return props
        .map(prop => obj[prop])
        .filter(Boolean)
        .join(' ');
};

const shouldDisableApprove = (account: WithTransactionStatus): boolean => {
    return account.lastTransactionStatus === TransactionStatuses.PENDING;
};

const shouldDisableBlock = (account: AccountDetailsDto & WithTransactionStatus): boolean => {
    return (
        account.status === AccountStatuses.BLOCKED ||
        account.status === AccountStatuses.DELETED ||
        account.lastTransactionStatus === TransactionStatuses.PENDING
    );
};

export default function B2bAccountModal(): ReactElement {
    const classes = useStyles();
    const modalState = useSelector(state => state.b2b.accounts.accountDetails);
    const statusSubmit = useSelector(state => state.b2b.accounts.accountStatusSubmit);
    const dispatch = useDispatch();
    const [fullHistoryShown, setFullHistoryShown] = useState(false);

    const handleClose = () => {
        dispatch(closeAccountDetails());
    };

    const isOpen = modalState.payload.isVisible;

    useEffect(() => {
        if (isOpen) {
            setFullHistoryShown(false);
        }
    }, [isOpen]);

    // defaulted to empty to keep modal height stable
    const {
        account = {} as AccountDetailsDto & WithTransactionStatus,
        address = {} as AccountAddressDto,
        audit = [] as AccountAuditDto[],
        fullAudit = [] as AccountAuditDto[],
    } = modalState.payload.content || {};

    const allChangedProps = useMemo(() => {
        const set = new Set<string>();

        fullAudit.forEach(a => {
            Object.keys(a.changedFields).forEach(k => set.add(k));
        });

        return Array.from(set);
    }, [fullAudit]);

    const handleApprove = () => {
        dispatch(submitAccountStatus(account.accountId, AccountStatuses.APPROVED));
    };

    const handleBlock = () => {
        dispatch(submitAccountStatus(account.accountId, AccountStatuses.BLOCKED));
    };

    const loadFullHistory = () => {
        dispatch(loadFullAudit(account.accountId));
        setFullHistoryShown(true);
    };

    const countrySpecific = getCountrySpecificPropNames(address);

    return (
        <Suspense fallback={<Loader />}>
            <LazyModal open={isOpen} handleClose={handleClose} disableBackdropClick={true} boxClass={classes.boxClass}>
                <Box data-cy="B2bAccountModal">
                    <Box>
                        <Typography variant="h5" className={classes.title}>
                            B2B Account details - {humanizeAccountStatus(account)}
                        </Typography>
                        <Box className={classes.inputBlock}>
                            <Box className={classes.inputRow}>
                                <TextFieldHistory
                                    label="Account Id"
                                    value={account.accountId}
                                    audit={audit}
                                    propName="accountId"
                                />
                                <TextFieldHistory
                                    label="Email address"
                                    value={account.email}
                                    audit={audit}
                                    propName="email"
                                />
                                <TextFieldHistory
                                    label={
                                        <div style={{ width: '188px' }}>
                                            VAT number{' '}
                                            <a href={VAT_CHECK_LINK} target="_blank" rel="noreferrer">
                                                VAT-checker
                                            </a>
                                        </div>
                                    }
                                    value={account.vatId}
                                    audit={audit}
                                    propName="vatId"
                                />
                            </Box>
                        </Box>
                        <Box className={classes.inputBlock}>
                            <Box className={classes.inputRow}>
                                <TextFieldHistory
                                    label="First Name"
                                    value={address.firstName}
                                    audit={audit}
                                    propName="firstName"
                                />
                                <TextFieldHistory
                                    label="Last Name"
                                    value={address.lastName}
                                    audit={audit}
                                    propName="lastName"
                                />
                                <TextFieldHistory
                                    label="Company Name"
                                    value={address.company}
                                    audit={audit}
                                    propName="company"
                                />
                                <TextFieldHistory
                                    label="Phone number"
                                    value={address.phone}
                                    audit={audit}
                                    propName="phone"
                                />
                            </Box>
                            <Box className={classes.inputRow}>
                                <TextFieldHistory
                                    label="Street name & number"
                                    value={joinAddress(address, countrySpecific.address)}
                                    audit={audit}
                                    propName={countrySpecific.address}
                                    isMultiple={true}
                                />
                                <TextFieldHistory
                                    label="Postal code"
                                    value={address.postalCode}
                                    audit={audit}
                                    propName="postalCode"
                                />
                                <TextFieldHistory label="City" value={address.city} audit={audit} propName="city" />
                                <TextFieldHistory
                                    label="Country"
                                    value={address.country}
                                    audit={audit}
                                    propName="country"
                                />
                            </Box>
                            <Box className={classes.inputRow}>
                                <TextFieldHistory
                                    label="Payment method"
                                    value={account.paymentMethod}
                                    audit={audit}
                                    propName="paymentMethod"
                                />
                            </Box>
                        </Box>
                        <Box className={classes.actionsBlock}>
                            <CustomButton
                                disabled={shouldDisableBlock(account)}
                                onClick={handleBlock}
                                variant="contained"
                                color="secondary"
                                data-cy="B2bAccountModal-Block"
                            >
                                Block
                            </CustomButton>
                            <CustomButton
                                disabled={shouldDisableApprove(account)}
                                onClick={handleApprove}
                                variant="contained"
                                color="primary"
                                data-cy="B2bAccountModal-Approve"
                            >
                                Approve
                            </CustomButton>
                        </Box>
                        {!fullHistoryShown && (
                            <Link variant="body2" onClick={loadFullHistory} data-cy="B2bAccountModal-LoadHistory">
                                load entire change log
                            </Link>
                        )}
                        {fullHistoryShown && (
                            <AccountChangesTimeline
                                audit={fullAudit}
                                propsToDisplay={allChangedProps}
                                isMultiple={true}
                                cy="B2bAccountModal-FullHistoryLog"
                            />
                        )}
                    </Box>
                    {(isInProgress(modalState) || isInProgress(statusSubmit)) && <Loader />}
                </Box>
            </LazyModal>
        </Suspense>
    );
}
