import { Container } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { AddSourceForm } from '../AddSourceForm/AddSourceForm';

const useStyles = makeStyles(theme =>
    createStyles({
        title: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    }),
);

export default function AddSourcePage(): React.ReactElement {
    const classes = useStyles();

    return (
        <div>
            <Container maxWidth="lg">
                <h2 className={classes.title}>Add a new Attribution source</h2>
                <AddSourceForm />
            </Container>
        </div>
    );
}
