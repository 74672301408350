import { TableCell } from '@material-ui/core';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';

export const CustomTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontSize: 14,
            fontWeight: 'bolder',
        },
    }),
)(TableCell);
