import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import React from 'react';

export interface SpinnerProps {
    boxClass?: string;
    iconClass?: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        box: {
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgb(255 255 255 / 50%)',
            zIndex: 1,
        },
        spin: {
            animation: 'spin 1s linear infinite',
        },
    }),
);

// use inside `position: relative` element
export const Spinner: React.FC<SpinnerProps> = ({ boxClass = '', iconClass = '' }: SpinnerProps) => {
    const classes = useStyles();

    return (
        <Box className={`${classes.box} ${boxClass}`}>
            <AutorenewIcon className={`${classes.spin} ${iconClass}`} />
        </Box>
    );
};
