/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Color } from '@material-ui/lab/Alert';

const STATE_CHANGE_LOADER = 'STATE_CHANGE_LOADER';
const STATE_CHANGE_STATUS_BAR = 'STATE_CHANGE_STATUS_BAR';
const STATE_CHANGE_STATUS_BAR_CONTENT = 'STATE_CHANGE_STATUS_BAR_CONTENT';
const STATE_SHOW_STATUS_BAR_MESSAGE = 'STATE_SHOW_STATUS_BAR_MESSAGE';

export const setShowLoader = (showLoader: boolean) => ({
    type: STATE_CHANGE_LOADER,
    showLoader,
});

export const setShowStatusBar = (showStatusBar: boolean) => ({
    type: STATE_CHANGE_STATUS_BAR,
    showStatusBar,
});

export const setStatusBarContent = (statusBarContent: string) => ({
    type: STATE_CHANGE_STATUS_BAR_CONTENT,
    statusBarContent,
});

export const showStatusMessage = (statusBarContent: string, severity: Color) => ({
    type: STATE_SHOW_STATUS_BAR_MESSAGE,
    statusBarContent,
    severity,
});

export interface VisualFeedBackElements {
    showStatusBar: boolean;
    statusBarContent: string;
    showLoader: boolean;
    severity: Color;
}

const initVisualFeedbackElements: VisualFeedBackElements = {
    showStatusBar: false,
    statusBarContent: '',
    showLoader: false,
    severity: 'info',
};

export const visualFeedbackReducer = (
    visualFeedbackElements = initVisualFeedbackElements,
    action,
): VisualFeedBackElements => {
    switch (action.type) {
        case STATE_CHANGE_LOADER:
            return {
                ...visualFeedbackElements,
                showLoader: action.showLoader,
            };
        case STATE_CHANGE_STATUS_BAR:
            return {
                ...visualFeedbackElements,
                showStatusBar: action.showStatusBar,
            };
        case STATE_CHANGE_STATUS_BAR_CONTENT:
            return {
                ...visualFeedbackElements,
                statusBarContent: action.statusBarContent,
            };
        case STATE_SHOW_STATUS_BAR_MESSAGE:
            return {
                ...visualFeedbackElements,
                showStatusBar: true,
                statusBarContent: action.statusBarContent,
                severity: action.severity,
            };

        default:
            return visualFeedbackElements;
    }
};
