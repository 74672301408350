import { Snackbar, SnackbarCloseReason } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import React, { SyntheticEvent } from 'react';

const Alert = (props): React.ReactElement => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(6),
    },
}));

export interface StatusSnackbarProps {
    open: boolean;
    handleClose: Function;
    text: string;
    severity?: Color;
}

export const StatusSnackbar = ({
    open,
    handleClose,
    text,
    severity = 'success',
}: StatusSnackbarProps): React.ReactElement => {
    const classes = useStyles();

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose as (event: SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void}
            key="status-snackbar"
            autoHideDuration={3000}
            className={classes.root}
        >
            <Alert onClose={handleClose} severity={severity} data-cy="StatusSnackbar-Alert">
                <strong>{text}</strong>
            </Alert>
        </Snackbar>
    );
};
