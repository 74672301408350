import { Box, Grid, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { PageDetailsDto } from 'apis/bnclCampaign';
import { FormikTextField } from 'components/common/Form/FormikTextField';
import { FormikErrors, useFormik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useSelector } from '../../../store';

type FormType = {
    title: string;
    subtitle: string;
};

type VendorBnclDetailsFormProps = {
    details?: PageDetailsDto;
};

export interface VendorBnclDetailsFormHandlers {
    /**
     * returns void if invalid
     */
    submit: () => Promise<FormType | void>;
    isModified: () => boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(4),
        },
        title: {
            fontSize: theme.typography.pxToRem(18),
            color: theme.palette.primary.main,
            fontWeight: 'bold',
        },
    }),
);

const validate = (values: FormType) => {
    const errors: FormikErrors<FormType> = {};

    if (!values.title) {
        errors.title = 'Enter Page Title';
    }

    if (!values.subtitle) {
        errors.subtitle = 'Enter Page Subtitle';
    }
    return errors;
};

const detailsToForm = (details: PageDetailsDto): FormType => ({
    title: details.title,
    subtitle: details.subtitle,
});

function VendorBnclDetailsForm({ details }: VendorBnclDetailsFormProps, ref): React.ReactElement {
    const classes = useStyles();
    const translations = useSelector(state => state.translations.list);

    const formik = useFormik<FormType>({
        initialValues: {
            title: translations.payload.find(item => item.key === 'bncl.landingPage.title')?.value || '',
            subtitle: translations.payload.find(item => item.key === 'bncl.landingPage.description')?.value || '',
        },
        onSubmit: async _ => _,
        validate,
    });

    useImperativeHandle(
        ref,
        (): VendorBnclDetailsFormHandlers => ({
            submit: async () => {
                const values = await formik.submitForm();
                return values;
            },
            isModified: () => {
                const initialValues = details ? detailsToForm(details) : formik.initialValues;
                return !_.isEqual(initialValues, formik.values);
            },
        }),
    );

    useEffect(() => {
        if (!details) return;
        formik.setValues(detailsToForm(details));
    }, [details]);

    return (
        <Box className={classes.container}>
            <Box component="h2" className={classes.title}>
                Page details
            </Box>
            <form data-cy="Bncl-Details-Form">
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <FormikTextField
                            label="Page title"
                            name="title"
                            fullWidth
                            required
                            formik={formik}
                            data-cy="Bncl-DetailsForm-Title"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <FormikTextField
                            label="Page subtitle"
                            name="subtitle"
                            fullWidth
                            required
                            formik={formik}
                            data-cy="Bncl-DetailsForm-Subtitle"
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default forwardRef(VendorBnclDetailsForm);
