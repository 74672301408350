import { Box, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Channel } from 'apis/attributionApi/channels';
import { SourcesCreateRequest } from 'apis/attributionApi/sources';
import { createSource, fetchChannels } from 'application/attributions/attributionsSlice';
import { showStatusMessage } from 'application/campaignForm/campaignCreate/visualFeedbackStore';
import { RequestState } from 'common/RequestState';
import CustomButton from 'components/common/CustomButton/CustomButton';
import { FormikTextField } from 'components/common/Form/FormikTextField';
import { Loader } from 'components/common/Loader/Loader';
import { useAttributionChannels } from 'components/hooks/useAttributionChannels';
import { FormikErrors, useFormik } from 'formik';
import { isInProgress } from 'helpers/requestStateHelper';
import * as React from 'react';
import { useDispatch, useSelector } from 'store';
export interface AddSourceFormFields {
    name?: string;
    shortCode?: string;
    channelId?: number;
    description?: string;
}

const validate = (values: AddSourceFormFields, channels: Channel[]): FormikErrors<AddSourceFormFields> => {
    const errors: FormikErrors<AddSourceFormFields> = {};

    // name
    if (channels.some(c => c.sources.some(s => s.name === values.name?.trim()))) {
        errors.name = 'Source with this name already exists';
    }

    if (!values.name?.trim()?.length) {
        errors.name = 'Name is required';
    }

    // shortCode
    if (channels.some(c => c.sources.some(s => s.shortCode === values.shortCode?.trim()))) {
        errors.shortCode = 'Source with this short code already exists';
    }

    if (values.shortCode?.trim()?.length !== 3) {
        errors.shortCode = 'Short Code should be 3 characters long';
    }

    // channel
    if (!values.channelId) {
        errors.channelId = 'Channel is required';
    }

    // description
    if (!values.description?.trim()?.length) {
        errors.description = 'Description is required';
    }

    return errors;
};

const onSubmit = async (values, { resetForm }, dispatch) => {
    const sourceCreateRequest: SourcesCreateRequest = {
        name: values.name!,
        shortCode: values.shortCode!,
        description: values.description!,
        channelIds: [values.channelId!],
    };
    const result = await dispatch(createSource(sourceCreateRequest));
    if (result.type.includes('fulfilled')) {
        await dispatch(fetchChannels());
        await dispatch(
            showStatusMessage(`Source ${values.name} (${values.shortCode}) was added successfully`, 'success'),
        );
        resetForm();
    } else {
        await dispatch(
            showStatusMessage(
                `Failed to add ${values.name} (${values.shortCode}) source. ${result.error?.message}`,
                'error',
            ),
        );
    }
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            backgroundColor: theme.palette.secondary.light,
        },
    }),
);

export const AddSourceForm = (): React.ReactElement => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [channels, channelsRequestState] = useAttributionChannels();
    const sourcesCreateRequest = useSelector(state => state.attributions.sourcesCreateRequest);
    const formik = useFormik<AddSourceFormFields>({
        initialValues: {
            name: '',
            shortCode: '',
            channelId: 0,
            description: '',
        },
        validate: values => validate(values, channels as Channel[]),
        onSubmit: (values, formikHelpers) => onSubmit(values, formikHelpers, dispatch),
    });

    if (!channels) {
        return <Loader />;
    }

    const isBusy = isInProgress(sourcesCreateRequest) || channelsRequestState === RequestState.InProgress;
    const selectedChannel = channels && channels.find(c => c.id === formik.values.channelId);

    const onChannelChange = (e, value) => {
        formik.setFieldValue('channelId', value?.id);
    };

    const onShortCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue(e.target.name, e.target.value?.toUpperCase());
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box component="div" className={classes.pageContainer} p={4}>
                <Grid container item sm={12} spacing={4}>
                    <Grid item sm={3}>
                        <FormikTextField
                            label="Name of the source"
                            name="name"
                            placeholder="e.g. Digital Photographer"
                            fullWidth
                            required
                            inputProps={{ maxLength: 150 }}
                            disabled={isBusy}
                            formik={formik}
                            data-cy="AddSource-Name"
                        />
                    </Grid>
                    <Grid item sm={2}>
                        <Autocomplete
                            value={selectedChannel || null}
                            options={channels}
                            getOptionLabel={o => o.name}
                            onChange={onChannelChange}
                            disabled={isBusy}
                            renderInput={params => {
                                return (
                                    <TextField
                                        {...params}
                                        label="Channel"
                                        name="channelId"
                                        error={!!formik.touched.channelId && !!formik.errors.channelId}
                                        helperText={!!formik.touched.channelId && formik.errors.channelId}
                                        InputLabelProps={{ shrink: true, required: true }}
                                        data-cy="AddSource-Channel"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item sm={2}>
                        <FormikTextField
                            label="Short code"
                            name="shortCode"
                            placeholder="e.g. DPH"
                            fullWidth
                            required
                            formik={formik}
                            inputProps={{ maxLength: 3 }}
                            onChange={onShortCodeChange}
                            disabled={isBusy}
                            data-cy="AddSource-ShortCode"
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <FormikTextField
                            label="Description"
                            name="description"
                            placeholder="e.g. Requested by Team NL"
                            fullWidth
                            required
                            formik={formik}
                            disabled={isBusy}
                            data-cy="AddSource-Description"
                        />
                    </Grid>
                    <Grid item sm={2}>
                        <CustomButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isBusy}
                            data-cy="AddSource-AddButton"
                        >
                            ADD SOURCE
                        </CustomButton>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};
