import React, { Fragment } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            marginRight: theme.spacing(2),
        },
    }),
);

export interface CampaignTitleProps {
    title: string;
    uuid: string;
}

export const CampaignTitle = ({ title, uuid }: CampaignTitleProps): React.ReactElement => {
    const classes = useStyles();

    return (
        <Fragment>
            <Chip
                label={
                    <div>
                        <strong>{'UUID: '}</strong>
                        <i data-cy="CampaignNav-uuid">{uuid}</i>
                    </div>
                }
                className={classes.chip}
            />
            <strong data-cy="CampaignNav-name">{title}</strong>
        </Fragment>
    );
};
