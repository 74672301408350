import { FormControl } from '@material-ui/core';
import * as React from 'react';
import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from './KeyboardDateTimePicker';

export type FormikDateTimePickerProps = Omit<KeyboardDateTimePickerProps, 'value' | 'onChange'> & {
    formik: any;
};

// formik value must by Date | null | undefined
export const FormikDateTimePicker = ({
    formik,
    name,
    fullWidth,
    format = 'dd/MM/yyyy HH:mm',
    ...props
}: FormikDateTimePickerProps): React.ReactElement => {
    if (!name) throw Error('name prop is required');

    const hasError = !!formik.touched[name] && !!formik.errors[name];

    const onChange = (date: Date | null) => {
        formik.setFieldValue(name, date);
    };

    return (
        <FormControl style={fullWidth ? { width: '100%' } : {}} error={hasError}>
            <KeyboardDateTimePicker
                name={name}
                {...props}
                format={format}
                error={hasError}
                value={formik.values[name]}
                onChange={onChange}
                fullWidth={fullWidth}
                helperText={hasError && formik.errors[name]}
            />
        </FormControl>
    );
};
