import { getRandomString } from 'helpers/generalHelper';
import { doRequest } from 'services/httpService';
import { oidc } from 'services/oidcService';
import { callApi } from './callApi';
import { CATEGORY_TYPE } from './seoPages';

const IMAGE_TMP_FOLDER = 'content/seo-pages/images/tmp';

export type PagesDto = {
    pageId: string;
    category: typeof CATEGORY_TYPE[keyof typeof CATEGORY_TYPE];
    vendors: {
        name: string;
        url: string;
    }[];
};

type SeoPageMetaDto = {
    title: string;
    description: string;
};

type OpenGraphMetaDto = {
    title: string;
    description: string;
    image?: string;
};

type MetaRobots = {
    follow: boolean;
    index: boolean;
};

type PageVendorMetadataDto = {
    meta: SeoPageMetaDto;
    openGraph: OpenGraphMetaDto;
    canonicalUrl: string;
    metaRobots: MetaRobots;
    structuredJson: string;
};

export type UploadedContentImage = {
    path: string;
    altText: string;
};

export type ContentImageToUpload = {
    file: File;
    altText: string;
};

export type ContentImage = UploadedContentImage | ContentImageToUpload;

export type PageTemplateDto = {
    templateType: string;
    blocks: [
        {
            blockType: string;
            images: UploadedContentImage[];
            title: string;
            description: string;
            ctaUrl: string;
            ctaText: string;
        },
        {
            blockType: string;
            text: string;
        },
    ];
};

type BlocksWithUploadableImages = {
    blockType: string;
    images?: ContentImage[];
}[];

export type BreadcrumbDto = {
    href: string;
    label: string;
};

export type PageVendorDto = {
    vendorName: string;
    pageUrl: string;
    status: string;
    metadata?: PageVendorMetadataDto;
    template: PageTemplateDto;
    breadcrumbs: BreadcrumbDto[];
};

export type PageDto = {
    pageId: string;
    category: string;
    vendors: Record<string, PageVendorDto>;
};

export async function uploadContentImages(files: File[]): Promise<void> {
    if (files.length === 0) return;

    const token = await oidc.getAccessToken();
    const config = await callApi('SeoCmsApiUrl', 'POST', '/custom/images/presigned-post', null, {}, token);

    await Promise.all(
        files.map(async file => {
            const formData = new FormData();
            Object.keys(config.fields).forEach(key => formData.append(key, config.fields[key]));
            const imagePath = `${IMAGE_TMP_FOLDER}/${file.name}`;
            formData.append('key', imagePath);
            formData.append('Content-Type', file.type);
            formData.append('file', file);
            await doRequest('POST', config.url, formData);
        }),
    );
}

function sanitizeImageName(name: string): string {
    const splittedName = name.split('.');
    const extension = splittedName.pop();
    const noExtensionName = splittedName.join('');

    const noSpacesName = noExtensionName.replaceAll(' ', '-');
    const noSpecialCharsName = noSpacesName.replaceAll(/[^A-Za-z0-9\-\_]/g, '');
    const timestamp = new Date().getTime();
    const randomString = getRandomString(5);

    return `${noSpecialCharsName}-${timestamp}-${randomString}.${extension}`;
}

function prepareImagesToUpload(blocks: BlocksWithUploadableImages): File[] {
    const imagesToUpload: File[] = [];

    for (const block of blocks) {
        if (!block.images) continue;
        block.images = block.images.map(image => {
            if (image['file']) {
                const { file, altText } = image as ContentImageToUpload;
                const fileName = sanitizeImageName(file.name);
                imagesToUpload.push(new File([file], fileName, { type: file.type }));
                return {
                    path: `${IMAGE_TMP_FOLDER}/${fileName}`,
                    altText,
                };
            }

            return image;
        });
    }

    return imagesToUpload;
}

export const getCustomPage = async (pageId: string): Promise<PageDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('SeoCmsApiUrl', 'GET', `/custom/pages/${pageId}`, null, {}, token);
};

export type CreateVendorCustomPageDto = {
    pageData: Omit<PageVendorDto, 'template'> & {
        pageId: string;
        template: Omit<PageTemplateDto, 'blocks'> & {
            blocks: BlocksWithUploadableImages;
        };
    };
    image?: any;
};

export const postCustomPage = async (data: CreateVendorCustomPageDto): Promise<void> => {
    const imagesToUpload = prepareImagesToUpload(data.pageData.template.blocks);
    await uploadContentImages(imagesToUpload);

    const token = await oidc.getAccessToken();
    const formData = new FormData();
    formData.append('createdPage', JSON.stringify(data.pageData));
    if (data.image) formData.append('image', data.image);
    await callApi('SeoCmsApiUrl', 'POST', '/custom/pages', formData, {}, token);
};

export type UpdateVendorCustomPageDto = {
    pageData: Omit<PageVendorDto, 'vendorName' | 'template'> & {
        template: Omit<PageTemplateDto, 'blocks'> & {
            blocks: BlocksWithUploadableImages;
        };
    };
    image?: any;
};

export const patchCustomPage = async (
    pageId: string,
    vendorName: string,
    data: UpdateVendorCustomPageDto,
): Promise<void> => {
    const imagesToUpload = prepareImagesToUpload(data.pageData.template.blocks);
    await uploadContentImages(imagesToUpload);

    const token = await oidc.getAccessToken();
    const formData = new FormData();
    formData.append('updatedPage', JSON.stringify(data.pageData));
    if (data.image) formData.append('image', data.image);
    await callApi('SeoCmsApiUrl', 'PATCH', `/custom/pages/${pageId}/vendors/${vendorName}`, formData, {}, token);
};

export const deleteVendorCustomPage = async (pageId: string, vendorName: string): Promise<PageDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('SeoCmsApiUrl', 'DELETE', `/custom/pages/${pageId}/vendors/${vendorName}`, null, {}, token);
};
