import { AuthenticationContext, AdalConfig, UserInfo, adalGetToken } from 'react-adal';
import { LocalStorageInstance } from 'services/localStorageService';
import { adalConfig } from 'config/adalConfig';

export class ActiveDirectoryService {
    private authContext: AuthenticationContext;
    private adalConfig: AdalConfig;

    constructor(adalConfigProvider: AdalConfig) {
        this.adalConfig = adalConfigProvider;
        this.authContext = new AuthenticationContext(adalConfigProvider);
    }

    login = (app: Function): void => {
        this.authContext.handleWindowCallback();
        if (!this.isAuthenticated && (window === window.parent || (window as any).Cypress)) {
            if (!this.authContext.isCallback(window.location.hash)) {
                if (
                    !this.authContext.getCachedToken(this.authContext.config.clientId) ||
                    (!(window as any).Cypress && !this.authContext.getCachedUser())
                ) {
                    this.authContext.login();
                } else {
                    app();
                }
            }
        } else if (this.isAuthenticated) {
            app();
        }
    };

    logout = (): void => {
        LocalStorageInstance.clearAll();
        this.authContext.logOut();
    };

    get getActiveDirectoryApplicationId(): string {
        return this.adalConfig.clientId;
    }

    get userInfo(): UserInfo {
        return this.authContext.getCachedUser();
    }

    get accessToken(): string | null {
        return this.authContext.getCachedToken(this.adalConfig.clientId);
    }

    get isAuthenticated(): boolean {
        return !!this.accessToken && !!this.userInfo;
    }

    /**
     * Attempts to silently renew the token, if that's not possible redirects to the Login page
     * @async
     */
    renewToken = async (): Promise<void> => {
        try {
            // Silent token refresh
            await adalGetToken(this.authContext, this.adalConfig.clientId);
        } catch (err) {
            if (err.msg === 'login_required') {
                // Force redirect to the login page
                this.authContext.login();
            }

            throw err;
        }
    };
}

export const activeDirectoryServiceInstance = new ActiveDirectoryService(adalConfig);
