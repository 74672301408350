import { Box, Button, FormControl, InputAdornment, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Account, AccountStatuses, SearchableTransactionStatuses } from 'apis/b2bAccounts';
import { fetchB2BAccounts, openAccountDetails, searchB2BAccounts } from 'application/b2b/accountsSlice';
import ClearableTextField from 'components/common/Form/ClearableTextField';
import { Loader } from 'components/common/Loader/Loader';
import useQuery from 'components/hooks/useQuery';
import { isLoading } from 'helpers/requestStateHelper';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import AccountModal from './AccountDetails/AccountModal';
import AccountsTable from './AccountsTable';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: '44.5px',
        },
        tableWrapper: {
            paddingTop: theme.spacing(2),
        },
        pageFilters: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            marginBottom: theme.spacing(2),
            '& .MuiTextField-root': {
                width: 370,
            },
        },
        selectFilter: {
            width: 200,
            marginRight: 'auto',
            marginLeft: theme.spacing(2),
        },
        loadButton: {
            marginTop: theme.spacing(2),
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        loadButtonContainer: {
            display: 'flex',
        },
        header: {
            fontSize: '1.7rem',
        },
    }),
);

export interface AccountsProps {
    optional?: string;
}

type SearchFiltersState = {
    status: string;
    search: string;
    ascendingOrder: boolean;
};

const Accounts: React.FC<AccountsProps> = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [searchFilters, setSearchFilters] = useState<SearchFiltersState>({
        status: AccountStatuses.REQUIRED,
        search: '',
        ascendingOrder: false,
    });
    const list = useSelector(state => state.b2b.accounts.list);
    const lastEvaluatedKey = useSelector(state => state.b2b.accounts.lastEvaluatedKey);
    const query = useQuery();
    const statusesOptions = [
        {
            label: 'New/updated account',
            value: 'Required',
        },
        {
            label: 'Pending account',
            value: 'pending',
        },
        {
            label: 'Blocked account',
            value: 'Blocked',
        },
        {
            label: 'Approved account',
            value: 'Approved',
        },
        {
            label: 'Failed account',
            value: 'failed',
        },
        {
            label: 'Deleted account',
            value: 'Deleted',
        },
    ];

    useEffect(() => {
        dispatch(fetchB2BAccounts(searchFilters.status, searchFilters.ascendingOrder));
    }, [searchFilters.status, searchFilters.ascendingOrder]);

    useEffect(() => {
        const id = query.get('accountId');
        if (id) {
            dispatch(openAccountDetails(id));
        }
    }, [query.get('accountId')]);

    const onLoadClick = () => {
        dispatch(fetchB2BAccounts(searchFilters.status, false, lastEvaluatedKey));
    };

    const onAccountClick = (item: Account) => {
        dispatch(openAccountDetails(item.accountId));
    };

    const onSearchAccount = () => {
        if (searchFilters.search) {
            dispatch(searchB2BAccounts(searchFilters.search.trim()));
        }
    };

    const onSorting = () => {
        setSearchFilters({
            ...searchFilters,
            ascendingOrder: !searchFilters.ascendingOrder,
        });
    };

    const isTransactionSearchStatus = SearchableTransactionStatuses.includes(searchFilters.status);

    return (
        <Box className={classes.wrapper}>
            {isLoading(list) && <Loader />}
            <Typography variant="subtitle1" component="h2" className={classes.header}>
                B2B Accounts
            </Typography>
            <Box className={classes.pageFilters}>
                <ClearableTextField
                    value={searchFilters.search}
                    onChange={val =>
                        setSearchFilters({
                            ...searchFilters,
                            search: val,
                        })
                    }
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            onSearchAccount();
                        }
                    }}
                    placeholder="Search account id or customer e-mail address"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    data-cy="B2BAccounts-FilterInput"
                    clearIconProps={{
                        'data-cy': 'B2BAccounts-FilterInputReset',
                    }}
                />
                <FormControl className={classes.selectFilter}>
                    <Select
                        id="select-status"
                        value={searchFilters.status}
                        name="status"
                        onChange={e =>
                            setSearchFilters({
                                ...searchFilters,
                                status: e.target.value as string,
                            })
                        }
                        fullWidth
                        data-cy="B2BAccounts-FilterSelect"
                    >
                        {statusesOptions.map(({ label, value }, idx) => (
                            <MenuItem key={idx} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <AccountsTable
                items={list.payload}
                onRowClick={onAccountClick}
                data-cy="B2BAccounts-table"
                onSorting={onSorting}
                isSortDisabled={isTransactionSearchStatus}
                ascendingOrder={searchFilters.ascendingOrder}
            />
            <Box className={classes.loadButtonContainer}>
                <Button
                    className={classes.loadButton}
                    disabled={!lastEvaluatedKey}
                    variant="contained"
                    color="primary"
                    data-cy="B2BAccounts-LoadBtn"
                    onClick={onLoadClick}
                >
                    Load more
                </Button>
            </Box>
            <AccountModal />
        </Box>
    );
};
export default Accounts;
