import { TextField, TextFieldProps } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import * as React from 'react';

export type ClearableTextFieldProps = TextFieldProps & {
    onChange: (value: unknown) => void;
    clearIconProps?: Record<string, any>;
};

const ClearableTextField = ({
    onChange,
    value,
    InputProps = {},
    clearIconProps = {},
    ...props
}: ClearableTextFieldProps): React.ReactElement => {
    return (
        <TextField
            value={value}
            onChange={e => onChange(e.target.value)}
            InputProps={{
                ...InputProps,
                endAdornment: value ? (
                    <ClearIcon onClick={() => onChange('')} style={{ cursor: 'pointer' }} {...clearIconProps} />
                ) : undefined,
            }}
            {...props}
        />
    );
};

export default ClearableTextField;
